import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/insuranceInfoPopup.css";
import providerefu from "../../Assets/imgs/provider-logo-one.webp";
import providerbima from "../../Assets/imgs/provider-logo-two.webp";
import rightarrow from "../../Assets/imgs/right-arrow.webp";
import { tagManagerEvents } from "../../utils/GoogleTagManager";
import EventsName from "../../constants/EventsName";

interface InsuranceProviderProps {
  partnersList: any;
  isPartnersListModalVisible: boolean;
  onHandleClosePartnersListModal: () => void;
  onPartnerListPressed: (partnerID: number, partnerData: any) => void;
  isLoading: boolean;
}

const InsuranceProvider = (props: InsuranceProviderProps) => {
  return (
    <>
      <Modal
        show={props.isPartnersListModalVisible}
        className="modal-provide"
        onHide={props.onHandleClosePartnersListModal}
      >
        <Modal.Header closeButton>
          <div className="modal-title">Insurance Provider</div>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="provider-modal">
            {props.partnersList.map((item: any, index: number) => (
              <div
                onClick={() => {
                  props.onPartnerListPressed(item.id, item);
                  tagManagerEvents(EventsName.partnerclick, "");
                }}
                className="provider-inner"
                style={{
                  pointerEvents: props.isLoading ? "none" : "auto",
                  opacity: props.isLoading ? 0.5 : 1,
                }}
              >
                <div className="bg-one"></div>
                <div className="pm_info">
                  <div className="efu-image">
                    <img src={item.logo} alt="" />
                  </div>
                  <div className="pm_name">{item.title}</div>
                </div>

                <div className="right-image">
                  <img src={rightarrow} alt="" />
                </div>
              </div>
            ))}
            {props.isLoading && <p style={{ textAlign: "center" }}>Loading</p>}

            {/* <div className="provider-inner">
              <div className="bg-one"></div>
              <div className="efu-image bima">
                <img src={providerbima} alt="" />
              </div>

              <div className="right-image">
                <img src={rightarrow} alt="" />
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InsuranceProvider;
