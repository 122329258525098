import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import Home from "./components/Home";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import HomeScreen from "./screens/HomeScreen";
import LandingScreen from "./screens/LandingScreen";
import PinScreen from "./screens/PinScreen";
import ProfileScreen from "./screens/ProfileScreen";
import UserContext from "./utils/UserContext";
import { username, password, gtmId } from "./utils/Config";
import { generateTokenService } from "./services/GlobalService";
import AppMessage from "./constants/AppMessage";

import TagManager from "react-gtm-module";

import LandingWaadaScreen from "./screens/LandingWaadaScreen";
import LandingBimaScreen from "./screens/LandingSehatBimaScreen";
import SignupScreen from "./screens/SignupScreen";
const App = () => {
  const [jwtToken, setJwtToken] = useState("");

  useEffect(() => {
    if (jwtToken.length === 0) generateToken();
    // handleCheckUser();
    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const updateJwtToken = (token: string) => {
    setJwtToken(token);
  };

  const generateToken = async () => {
    try {
      let data = {
        username,
        password,
      };
      let response: any = await generateTokenService(data);
      if (response.result) {
        global.authToken = response.result;
        updateJwtToken(response.result);
        // console.log("Token Generated in Home Screen: ", response.result);
        // console.log("Token Generated in Home Screen: ", jwtToken);
      } else {
        console.log(AppMessage.initializationFailed);
      }
    } catch (e) {
      console.warn("Generate Token Error: ", e);
    }
  };

  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={{}}>
          <Routes>
            TaqdeerPrivacyPolicy
            <Route path="/home" element={<HomeScreen />} />
            <Route
              path="/signup/:planName/:utmSource?"
              element={<SignupScreen token={jwtToken} />}
            />
            <Route path="/landing" element={<LandingScreen />} />
            <Route
              path="/landingwaada"
              element={<LandingWaadaScreen token={jwtToken} />}
            />
            <Route path="/pin" element={<PinScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              path="/landingbimasehat"
              element={<LandingBimaScreen token={jwtToken} />}
            />
            <Route path="/*" element={<Navigate to={"/home"} />} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
