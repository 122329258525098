import React from "react";
import landingbimalogo from "../Assets/imgs/landingbimalogo.webp";
import bimamainlanding from "../Assets/imgs/bimamainlanding.webp";
import firkFormLogoImg from "../Assets/imgs/landingOne-assets/fikr-logo.webp";
import "../styles/LandingBima.css";
import BackArrow from "../Assets/imgs/back-arrow.webp";

interface planType {
  id: number;
  title: string;
  charging: number;
  type: string;
  description: string;
  cover_benefit: number;
  sub_package: string;
}

interface LandingProps {
  msisdn: string;
  onMsisdnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg: string;
  isLoading: boolean;
  showOtp: boolean;
  onResendClick: () => void;
  counter: number;
  backButton: () => void;
  otp: any;
  inputRefs: any;
  onOTPSubmit: () => void;
  onPINInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onPINInput: (index: number, e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressSendOTPButton: () => Promise<void>;
  loginLoading: boolean;
  planData: planType | null;
}

const LandingSehatBima = (props: LandingProps) => {
  return (
    <div>
      <div className="landing-sectionOne  landingBimaSection">
        <div className="landing-content">
          <div className="logo-img">
            <img src={landingbimalogo} alt="logo" width="120" height="60" />
          </div>
          <div className="landing-home-img">
            <img
              src={bimamainlanding}
              alt="main img"
              width="375"
              height="291"
            />
          </div>
          <div className="fikr-landing-form">
            <div className="fikr-form">
              <div className="fikr-form-logo">
                <img src={firkFormLogoImg} alt="" width="70" height="58" />
              </div>
              {props.showOtp == false && (
                <>
                  <div className="text">Enter Phone Number</div>
                  <div className="fikr-input">
                    <span>+92</span>
                    <input
                      type="tel"
                      value={props.msisdn}
                      placeholder="3xxxxxxxxx"
                      onChange={props.onMsisdnChange}
                      maxLength={10}
                    />
                  </div>
                </>
              )}

              {/* OTPPPPPPP */}
              {props.showOtp && (
                <>
                  <div className="back__arrow">
                    <img
                      src={BackArrow}
                      alt="backarrow"
                      width={25}
                      height={25}
                      onClick={props.backButton}
                    />
                  </div>

                  <div className="otp-form">
                    <div className="text">
                      Please enter OTP received via SMS
                    </div>
                    <div className="fikr-input">
                      {props.otp.map((value: any, index: any) => (
                        <input
                          key={index}
                          ref={(el) => (props.inputRefs.current[index] = el)}
                          type="tel"
                          name="one"
                          maxLength={1}
                          value={value}
                          onChange={(e) => props.onPINInputChange(index, e)}
                          onKeyDown={(e) => props.onPINInput(index, e)}
                        />
                      ))}
                    </div>

                    {/* <p className="error">error</p> */}

                    <div className="charges-text">
                      <div>
                        <div
                          className="__resendOtp"
                          style={{
                            display: props.counter > 0 ? "none" : "block",
                          }}
                        >
                          Didn't receive the OTP?{" "}
                          {props.isLoading ? (
                            <span>Resending OTP</span>
                          ) : (
                            <span
                              id="otresendSpan"
                              onClick={props.onResendClick}
                            >
                              Resend
                            </span>
                          )}
                        </div>

                        <div
                          className="otcounter"
                          id="otcounter"
                          style={{
                            display: props.counter > 0 ? "block" : "none",
                          }}
                        >
                          00:
                          {props.counter < 10
                            ? `0${props.counter}`
                            : props.counter}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* END */}
              {/* <p className="error">error</p> */}

              {props.errorMsg.length > 0 ? (
                <p className="error">{props.errorMsg}</p>
              ) : null}
              {props.showOtp ? (
                <>
                  {props.loginLoading ? (
                    <button>Logging...</button>
                  ) : (
                    <button onClick={props.onOTPSubmit}>Login</button>
                  )}
                </>
              ) : (
                <>
                  {props.isLoading ? (
                    <button>Sending Otp...</button>
                  ) : (
                    <button onClick={props.onPressSendOTPButton}>
                      Send Otp
                    </button>
                  )}
                </>
              )}

              <>
                {props.planData && (
                  <div>
                    <div className="charges-text">
                      Price Point: {props.planData.charging} PKR per{" "}
                      {props.planData.type.toLowerCase()}
                    </div>
                    <div className="bimapolicybenifits">
                      <ul>
                        {/* {props.planData.description
                          .split(",")
                          .map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                          ))} */}
                        <li>
                          Hospitalization benefit PKR 500/30 nights in a year.
                        </li>
                        <li>24/7 unlimited doctor consultations</li>
                      </ul>
                    </div>
                  </div>
                )}
              </>
              {/* <div className="charges-text">Price Point : 1.2 PKR per day*</div>
              <div className="bimapolicybenifits">
                <ul>
                  <li>Hospitalization benefit PKR 500/ 30 nights in a year.</li>
                  <li>24/7 unlimited doctor consultations</li>
                </ul>
              </div> */}
              <div className="term-conditon">
                <a href="/privacypolicy" target="_blank">
                  {" "}
                  Privacy Policy{" "}
                </a>
                <div className="dot"></div>
                <a href="/terms" target="_blank">
                  {" "}
                  Terms & Conditions{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSehatBima;
