import React, { useContext, useState, useEffect, useRef } from "react";
import Home from "../components/Home";
import UserContext from "../utils/UserContext";
import AppMessage from "../constants/AppMessage";

import {
  generateTokenService,
  getInsuranceCategoriesService,
  getPartnersListService,
  getPartnersPoliciesService,
  getPolicyDetailsService,
  getRelationsService,
  purchasePolicyService,
  sendPurchaseOtpService,
} from "../services/GlobalService";
import { useLocation, useNavigate } from "react-router-dom";
import {
  checkOperatorService,
  checkUserService,
  generateOTPService,
  getProfileService,
  LoginService,
  subscriptionService,
  updateProfileService,
} from "../services/LandingService";
import { getErrorData } from "../utils/CommonFunctions";
import moment from "moment";
import {
  tagManagerEvents,
  tagManagerParamsEvents,
} from "../utils/GoogleTagManager";
import EventsName from "../constants/EventsName";
import { title } from "process";

let coverId = 0;
let categoryId = 0;
let partnerId = 0;
let policyId = 0;
let isPrepaid = true;
let policyPlans: any = [];
let selectedPolicy: any = [];
let selectedPlan: any = [];
let selectedCategory: any = [];
let selectedPartner: any = [];

// let planId = 0;
// let user_Id: any = "";

const HomeScreen = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const location = useLocation();
  // const { user_Id } = location.state as { user_Id: number };
  // let user_Id = 27;
  const [insuranceCategories, setInsuranceCategories] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [partnerPolicies, setPartnerPolicies] = useState([]);
  const [policyDetails, setPolicyDetails] = useState<any>([]);
  const [isNominee, setIsNominee] = useState(false);
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [isMobilePolicy, setIsMobilePolicy] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userpackage, setUserPackage] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [user_Id, setUserId] = useState<any>("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [timer, setTimer] = useState<number>(30);
  const [showResendButton, setShowResendButton] = useState<boolean>(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPSending, setIsOTPSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [showPaymentOtpPopup, setShowPaymentOtpPopup] = useState(false);
  const [selectedCoverId, setSelectedCoverId] = useState<number | null>(null);
  const [isConsentChecked, setIsConsentChecked] = useState<boolean>(false);

  const [showPaymentPlanPopup, setShowPaymentPlanPopup] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState<string>("jazzcash");
  // States to manage visibility of Pop Up Modals
  const [isPartnersListModalVisible, setPartnersListModalVisible] =
    useState(false);
  const [isPartnerPoliciesModalVisible, setPartnerPoliciesModalVisible] =
    useState(false);
  const [isPolicyDetailsModalVisible, setPolicyDetailsModalVisible] =
    useState(false);
  const [isUserVerificationModalVisible, setUserVerificationModalVisible] =
    useState(false);
  const [isUserDetailsModalVisible, setUserDetailsModalVisible] =
    useState(false);
  const [isUserProfileModalVisible, setUserProfileModalVisible] =
    useState(false);
  const [isPaymentSummaryModalVisible, setIsPaymentSummaryModalVisible] =
    useState(false);

  const [activeTab, setActiveTab] = useState<string>("Personal");
  const [isNomineeOpen, setIsNomineeOpen] = useState<boolean>(false);
  const [isPersonalOpen, setIsPersonalOpen] = useState<boolean>(true);
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const [noOfBeneficiaries, setNoOfBeneficiaries] = useState<number>(1);
  const [expandedStates, setExpandedStates] = useState([false]);

  const [newUserData, setNewUserData] = useState<any>({
    firstName: "",
    lastName: "",
    gender: "",
    cnic: "",
    email: "",
    dob: "",
    address: "",
  });

  const [beneficiariesData, setBeneficiariesData] = useState([
    {
      cnic: "",
      name: "",
      contact_number: "",
      relation_id: "",
    },
  ]);
  const [nomineeData, setNomineeData] = useState<any>({
    cnic: "",
    name: "",
    contact_number: "",
    relation_id: "",
  });

  const [mobileData, setMobileData] = useState<any>({
    phone_make: "",
    phone_model: "",
    imei_1: "",
    imei_2: "",
  });

  const [nomineeDataError, setnomineeDataError] = useState({
    cnic: false,
    name: false,
    msisdn: false,
    relationship: false,
  });

  const [beneficiariesDataError, setBeneficiariesDataError] = useState([
    {
      cnic: false,
      name: false,
      msisdn: false,
      relationship: false,
    },
  ]);

  const [errors, setErrors] = useState<any>({ nominee: {}, beneficiaries: [] });
  const [relationsData, setRelationsData] = useState<any>([]);
  const [personalData, setPersonalData] = useState<any>(null);
  const options = Array.from({ length: 10 }, (_, i) => i + 1);

  useEffect(() => {
    isPrepaid = true;
    let jwtToken = global.authToken;
    if (jwtToken) {
      console.log("Token in Home Screen: ", jwtToken);
      getInsuranceCategories();
      getRelationsList();
      let user_Id = Number(localStorage.getItem("user_Id"));
      if (user_Id) {
        setUserId(user_Id);
        // setUserId(user_Id);
        let _subPackage = localStorage.getItem("subpackage");
        if (_subPackage && _subPackage.toLowerCase() === "post") {
          // setIsPrepaid(false);
          isPrepaid = false;
        }
        handleGetUserProfile(user_Id);
      }
    }
  }, [global.authToken]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setShowResendButton(true);
    }
  }, [timer]);

  useEffect(() => {
    if (isOTPSent) {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  }, []);
  useEffect(() => {
    setErrorMsg("");
  }, [
    isPartnersListModalVisible,
    isPartnerPoliciesModalVisible,
    isPolicyDetailsModalVisible,
    isUserVerificationModalVisible,
    isUserDetailsModalVisible,
    isUserProfileModalVisible,
    showPaymentPlanPopup,
    showPaymentOtpPopup,
    isPaymentSummaryModalVisible,
    showSuccessPopUp,
  ]);

  const toggleExpandedState = (index: number) => {
    const newStates = [...expandedStates];
    newStates[index] = !newStates[index];
    setExpandedStates(newStates);
  };

  const handleUpdateNoOfBeneficiaries = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    try {
      setNoOfBeneficiaries(Number(event.target.value));
      let count = parseInt(event.target.value);
      setBeneficiariesData((prevValue) => {
        if (count === prevValue.length) {
          return prevValue;
        }
        if (count > prevValue.length) {
          return [
            ...prevValue,
            ...Array(count - prevValue.length).fill({
              cnic: "",
              name: "",
              contact_number: "",
              relation_id: "",
            }),
          ];
        }
        if (count < prevValue.length) {
          return [...prevValue.slice(0, count)];
        }

        return prevValue;
      });
    } catch (error) {
    } finally {
      setNoOfBeneficiaries(Number(event.target.value));
    }
    console.log("No of beneficaries", noOfBeneficiaries);
  };

  const validateCNIC = (cnic: string) => /^[0-9]{13}$/.test(cnic);
  const validateContactNumber = (number: string) => /^[0-9]{11}$/.test(number);
  const validateName = (name: string) => /^[a-zA-Z]+ [a-zA-Z]+$/.test(name);

  const handleNomineeInputChange = (field: string, value: string) => {
    const updatedNomineeData = { ...nomineeData, [field]: value };
    setNomineeData(updatedNomineeData);

    // Validation
    let error = "";
    if (field === "cnic" && !validateCNIC(value)) {
      error = "CNIC must be exactly 13 digits.";
    } else if (field === "contact_number" && !validateContactNumber(value)) {
      error = "Contact number must be exactly 11 digits.";
    } else if (field === "name" && !validateName(value)) {
      error = "Name should contain at least two words.";
    }

    setErrors({ ...errors, nominee: { ...errors.nominee, [field]: error } });
  };

  const handleDeviceInfoInputChange = (field: string, value: string) => {
    // console.log("hello", field, value);
    const updatedDeviceInfoData = { ...mobileData, [field]: value };
    setMobileData(updatedDeviceInfoData);
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedBeneficiariesData = [...beneficiariesData];
    updatedBeneficiariesData[index] = {
      ...updatedBeneficiariesData[index],
      [field]: value,
    };
    setBeneficiariesData(updatedBeneficiariesData);

    // Validation
    let error = "";
    if (field === "cnic" && !validateCNIC(value)) {
      error = "CNIC must be exactly 13 digits.";
    } else if (field === "contact_number" && !validateContactNumber(value)) {
      error = "Contact number must be exactly 11 digits.";
    } else if (field === "name" && !validateName(value)) {
      error = "Name should contain at least two words.";
    }

    const updatedErrors = [...errors.beneficiaries];
    updatedErrors[index] = { ...updatedErrors[index], [field]: error };
    setErrors({ ...errors, beneficiaries: updatedErrors });
  };

  const onSaveDataPressed = () => {
    console.log("Nominee Data: ", nomineeData);
    console.log("Beneficiaries Data: ", beneficiariesData);
    onHandleSubmitUserDetailsPressed(nomineeData, beneficiariesData);
  };

  const handleNewUserInputChange = (field: string, value: string) => {
    const updatedNewUserData = { ...newUserData, [field]: value };
    setNewUserData(updatedNewUserData);
  };
  const handleSaveUserData = () => {
    setErrorMsg("");
    console.log("UserData: ", newUserData);
    let newUserErrorText = "";
    let emailRegex = new RegExp(
      "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$"
    );
    Object.keys(newUserData).map((key) => {
      if (
        key === "email" &&
        !emailRegex.test(newUserData[key as keyof typeof newUserData])
      ) {
        newUserErrorText =
          "Email entered is not valid. Please enter a valid email address";
      } else if (
        key === "cnic" &&
        (newUserData[key as keyof typeof newUserData].trim().length !== 15 ||
          !/^[1-7]/.test(newUserData[key as keyof typeof newUserData]))
      ) {
        newUserErrorText =
          "CNIC is not valid. Please enter a valid 13-digit CNIC";
      } else if (
        key === "dob" &&
        newUserData[key as keyof typeof newUserData]
      ) {
        const dateOfBirth = newUserData[key as keyof typeof newUserData];
        if (moment().diff(dateOfBirth, "years") < 18) {
          newUserErrorText = "You must be at least 18 years old to register";
          // error = true;
        }
      } else if (
        newUserData[key as keyof typeof newUserData].trim().length === 0
      ) {
        newUserErrorText = "Please complete all fields";
      }
    });
    if (newUserErrorText) {
      // showToast(AppMessage.nomineeIncompleteInfo);
      setErrorMsg(newUserErrorText);
      return;
    } else {
      onHandleSetUpUserProfile(newUserData);
    }
  };

  const onHandleSubmitUserDetailsPressed = (
    policyData: any,
    beneficiariesData: any
  ) => {
    try {
      setErrorMsg("");
      let nomineeErrorText = "";
      let benefErrorText = "";
      console.warn(
        "runnong",
        policyDetails.policy.is_beneficiary,
        beneficiariesData
      );
      if (policyDetails.policy.is_nominee) {
        // let _nomineeDataError = {...policyData};
        Object.keys(policyData).map((key) => {
          if (
            key === "contact_number" &&
            policyData[key as keyof typeof policyData].trim().length !== 10
          ) {
            nomineeErrorText =
              "Nominee mobile number is not valid. Please enter a valid 10-digit mobile number";
          } else if (
            key === "cnic" &&
            (policyData[key as keyof typeof policyData].trim().length !== 15 ||
              !/^[1-7]/.test(policyData[key as keyof typeof policyData]))
          ) {
            nomineeErrorText =
              "Nominee CNIC is not valid. Please enter a valid 13-digit CNIC";
          } else if (
            policyData[key as keyof typeof policyData].trim().length === 0
          ) {
            nomineeErrorText = "Please complete all Nominee fields";
          }
        });
      }
      if (policyDetails.policy.is_beneficiary) {
        if (beneficiariesData.length > 0) {
          beneficiariesData.forEach((item: any, index: any) => {
            Object.keys(item).map((key) => {
              if (
                key === "cnic" &&
                (item[key].trim().length !== 15 || !/^[1-7]/.test(item[key]))
              ) {
                benefErrorText = `Beneficiary ${
                  index + 1
                } CNIC is not valid. Please enter a valid 13-digit CNIC`;
              } else if (
                key === "contact_number" &&
                item[key].trim().length !== 10
              ) {
                benefErrorText = `Beneficiary ${
                  index + 1
                } mobile number is not valid. Please enter a valid 10-digit mobile number`;
              } else if (item[key].trim().length === 0) {
                benefErrorText = "Please complete all fields";
              }
            });
          });
        } else {
          benefErrorText = "Atleast one beneficiary is required";
        }
      }
      if (policyDetails.policy.is_mobile_policy) {
        for (let key in mobileData) {
          console.warn("key ", key);
          if (
            mobileData[key as keyof typeof mobileData].trim().length === 0 &&
            key !== "imei_2"
          ) {
            console.warn("here ", key);
            benefErrorText = "Please complete all fields";
          } else if (
            key === "imei_1" &&
            mobileData[key as keyof typeof mobileData].length !== 15
          ) {
            benefErrorText = "IMEI must be exactly 15 digits.";
          } else if (
            key === "imei_2" &&
            mobileData.imei_2 &&
            mobileData.imei_2.length !== 15
          ) {
            benefErrorText = "IMEI 2 must be exactly 15 digits or empty";
          } else if (
            mobileData.imei_1.length > 0 &&
            mobileData.imei_2.length > 0 &&
            mobileData.imei_1 === mobileData.imei_2
          ) {
            benefErrorText = "IMEI 1 and IMEI 2 must be different.";
          }

          if (benefErrorText) {
            break;
          }
        }
      }
      if (nomineeErrorText || benefErrorText) {
        // showToast(AppMessage.nomineeIncompleteInfo);
        setErrorMsg(nomineeErrorText ? nomineeErrorText : benefErrorText);
        return;
      }
      handleUpdateNomineeData(policyData);
      handleUpdateBeneficiariesData(beneficiariesData);
      setUserDetailsModalVisible(false);
      setShowPaymentPlanPopup(true);
    } catch (error) {}
  };
  const handleRadioChange = (
    coverId: number,
    coverDetails: any,
    policyDetails: any
  ) => {
    console.log("Cover Selected:::", coverDetails, coverId, policyDetails);
    selectedPlan = coverDetails;
    selectedPolicy = policyDetails;
    setSelectedCoverId(coverId);
  };

  const handleSelectCover = () => {
    console.log(
      "Title of Selected Plan:::",
      selectedPlan.title,
      policyDetails.policy.title
    );
    if (policyDetails.policy.is_purchased) {
      setErrorMsg("This policy is already purchased");
      return;
    }
    if (selectedCoverId !== null && isConsentChecked) {
      // tagManagerParamsEvents(EventsName.planSelected, "", {
      //   planName: selectedPlan.title,
      //   policyName: policyDetails.policy.title,
      // });
      setErrorMsg("");
      onHandleSelectCover(selectedCoverId);
    } else if (selectedCoverId === null) {
      setErrorMsg("Please select a cover");
    } else {
      setErrorMsg("Please check the consent");
    }
  };

  const handleUpdateConsent = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  const handleUpdateActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleToggleNominee = () => {
    setIsNomineeOpen(!isNomineeOpen);
  };

  const handleUpdatePaymentMethod = (planName: string) => {
    setPaymentPlan(planName);
  };

  const handleTogglePersonal = () => {
    setIsPersonalOpen(!isPersonalOpen);
  };

  const handleToggleMobile = () => {
    // console.log("Toggle Mobile Triggered");
    setIsMobileOpen(!isMobileOpen);
  };

  const onShowDownloadPopup = () => {
    setShowDownloadPopup(true);
  };

  const onHideDownloadPopup = () => {
    setShowDownloadPopup(true);
  };

  const handleUpdateBeneficiariesData = (data: any) => {
    // console.log("Incoming Data", data);
    setBeneficiariesData(data);
    // console.log("Beneficiaries Data: ", beneficiariesData);
  };

  const handleUpdateNomineeData = (data: any) => {
    // console.log("Incoming Data", data);
    setNomineeData(data);
    // console.log("Nominee Data: ", nomineeData);
  };

  const getRelationsList = async () => {
    try {
      let response = await getRelationsService();
      if (response.result) setRelationsData(response.result);
    } catch (error) {
      console.log("Error while fetching relations: ", error);
    }
  };

  const getInsuranceCategories = async () => {
    try {
      let response: any = await getInsuranceCategoriesService();
      if (response.result) {
        setInsuranceCategories(response.result);
      } else {
        console.log(AppMessage.noData);
      }
    } catch (error) {
      console.warn("Get Insurance Categories Error: ", error);
      // console.log(AppMessage.initializationFailed);
    }
  };

  const handleGetUserProfile = async (user_id: any) => {
    try {
      let response = await getProfileService({ user_id });
      if (response.result) {
        setPersonalData({
          ...response.result,
          profile_pic_url: response.result.profile_pic_url
            ? response.result.profile_pic_url + "?time=" + new Date()
            : "",
        });
        // console.log("User Profile: ", response.result);
      }
    } catch (error) {
      console.log("Error while checking user profile: ", error);
    }
  };

  const onCategoryPressed = async (id: number, category: any) => {
    console.log("Hello Stranger: ", id);
    try {
      resetStates();
      selectedCategory = category;
      setIsLoading(true);
      categoryId = id;
      let response: any = await getPartnersListService(id);
      if (response.result) {
        setIsLoading(false);
        console.log("List of Partners: ", response.result);
        setPartnersList(response.result);
        setPartnersListModalVisible(true);
        // setInsuranceCategories(response.result);
      } else {
        console.log(AppMessage.noData);
        setIsLoading(false);
        // Display a "No Data Found" Pop Up/ Toast over here
      }
    } catch (error) {
      console.warn("Get Partners List Error: ", error);
      // console.log(AppMessage.initializationFailed);
    } finally {
      setIsLoading(false);
    }
  };

  const onPartnerListPressed = async (partnerID: number, partnerData: any) => {
    try {
      selectedPartner = partnerData;
      setIsLoading(true);
      partnerId = partnerID;
      // console.log("categray==========>", partnerData.title);
      let response: any = await getPartnersPoliciesService(
        categoryId,
        partnerID
      );
      if (response.result) {
        setIsLoading(false);
        // tagManagerParamsEvents(EventsName.insuranceProvider, "", {
        //   title: partnerData.title,
        // });
        console.log("List of Partner Policies: ", response.result);
        setPartnerPolicies(response.result);
        setPartnersListModalVisible(false);
        setPartnerPoliciesModalVisible(true);
        // setInsuranceCategories(response.result);
      } else {
        setIsLoading(false);
        console.log(AppMessage.noData);
        // Display a "No Data Found" Pop Up/ Toast over here
      }
    } catch (error) {
      console.warn("Get Partner Policies List Error: ", error);
      setIsLoading(false);
      // console.log(AppMessage.initializationFailed);
    } finally {
      setIsLoading(false);
    }
  };

  const filterPlansByPackage = (_plans: any) => {
    if (_plans.length > 0) {
      policyPlans = _plans.filter((item: any) => {
        if (isPrepaid) {
          return item.type.toLowerCase() === "daily";
        }
        return item.type.toLowerCase() === "monthly";
      });
    } else {
      policyPlans = [];
    }
  };

  const onPartnerPolicyPressed = async (
    policy_id: number,
    policyTitle: string
  ) => {
    try {
      console.log("Policy Id in partner policy pressed::::", policyTitle);
      // tagManagerParamsEvents(EventsName.policyDetail, "", {
      //   title: policyTitle,
      // });
      policyId = policy_id;
      let data = {};
      console.log("User Id in partner policy pressed::::", user_Id);
      if (user_Id) {
        data = {
          policy_id,
          user_id: user_Id,
        };
      } else {
        data = {
          policy_id,
        };
      }
      setIsLoading(true);
      let response: any = await getPolicyDetailsService(data);
      if (response.result) {
        setIsLoading(false);
        console.log("Policy Details: ", response.result);
        filterPlansByPackage(response.result.plans);
        setPolicyDetails(response.result);
        setIsBeneficiary(response.result.policy.is_beneficiary);
        setIsMobilePolicy(response.result.policy.is_mobile_policy);
        setIsNominee(response.result.policy.is_nominee);
        setPartnerPoliciesModalVisible(false);
        setSelectedCoverId(null);
        setIsConsentChecked(false);
        setErrorMsg("");
        setPolicyDetailsModalVisible(true);
        // setInsuranceCategories(response.result);
      } else {
        setIsLoading(false);
        console.log(AppMessage.noData);
        // Display a "No Data Found" Pop Up/ Toast over here
      }
    } catch (error) {
      setIsLoading(false);
      console.warn("Get Policy Details Error: ", error);
      // console.log(AppMessage.initializationFailed);
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleSelectCover = (_coverId: number) => {
    coverId = _coverId;
    console.log("Id of the cover selected: ", coverId);
    setPolicyDetailsModalVisible(false);
    // let userId = localStorage.getItem("user_Id");
    if (user_Id) {
      console.log("personalData :; ", personalData);
      if (personalData) {
        console.log("aaa");
        if (
          personalData.name === null ||
          personalData.cnic === null ||
          personalData.email === null
        ) {
          console.log("cccc");
          setUserProfileModalVisible(true);
        } else {
          console.log("dddd");
          setUserDetailsModalVisible(true);
        }
      } else {
        console.log("bbbb");
        setUserProfileModalVisible(true);
      }
    } else {
      console.log("User not Found");
      setUserVerificationModalVisible(true);
    }
  };

  const handleMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    let regex = new RegExp("^(3[0-9]*|)$");
    if (regex.test(input)) {
      setMsisdn(input);
      if (input.length < 10) {
        setIsOTPSent(false);
      }
    }
  };

  const handlePINInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      handleCodeChange(index, value);
      if (index < inputRefs.current.length - 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...otp];
      newCode[index] = value;
      setOtp(newCode);
    }
  };

  const handlePINInput = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && otp[index] !== "") {
      if (index >= 0) {
        handleCodeChange(index, "");
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          inputRefs.current[index]?.focus();
        }
      }
    }
  };

  const handleOnPressSendOTPButton = async () => {
    setIsOTPSent(false);
    setOtp(["", "", "", ""]);
    setErrorMsg("");
    if (msisdn.length === 10) {
      handleCheckOperator();
    } else {
      setErrorMsg(AppMessage.msisdnLength);
    }
  };
  const handleCheckOperator = async () => {
    try {
      setIsLoading(true);
      let response = await checkOperatorService({ msisdn });
      console.log("isOther Check: ", response.result.isOther);
      if (response.result.isOther === false) {
        console.log("Msisdn belongs to Jazz: ");
        let _package = response.result.package;
        setUserPackage(_package);
        // localStorage.setItem("package", _package);
        handleSendOTP(_package);
      } else {
        setIsLoading(false);
        setErrorMsg(AppMessage.otherOperator);
      }
    } catch (error) {
      setErrorMsg("Unable to send OTP,please try again");
      setIsLoading(false);
      console.log("Error while sending OTP: ", error);
    }
  };

  const handleSendOTP = async (_package: string) => {
    // setIsLoading(true);
    try {
      let response = await generateOTPService({ msisdn });
      if (response.result) {
        console.log("OTP send successfully: ", response.result);
        // tagManagerEvents(EventsName.sendOtp, "");
        setIsLoading(false);
        setIsOTPSent(true);
        setTimer(30);
        setShowResendButton(false);
        // navigate("/OTPEntry", { state: { msisdn, _package } });
      } else {
        setIsLoading(false);
        setErrorMsg("Unable to send OTP,please try again");
      }
    } catch (error) {
      setErrorMsg("Unable to send OTP,please try again");
      setIsLoading(false);
      console.log("Error while sending OTP: ", error);
    }
  };

  const handleOTPSubmit = () => {
    console.log("OTP Entered is: ", otp.join(""));
    let _otp = otp.join("");
    if (_otp.length === 4) {
      handleCheckUser(_otp);
    } else {
      setErrorMsg("Please enter 4 digits OTP.");
    }
  };

  const handleCheckUser = async (_otp: string) => {
    try {
      setIsLoading(true);
      let response = await checkUserService({ msisdn });
      if (response.result.active === true) {
        console.log("User is active");
        handleLoginUser(_otp);
      } else {
        console.log("User is not active");
        handleSubscribeUser(_otp);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
      console.log("Error while checking user: ", error);
    }
  };

  const handleSubscribeUser = async (_otp: string) => {
    console.log("I am in handle Subscribe User");
    try {
      let response = await subscriptionService({
        msisdn: msisdn,
        otp: _otp,
        device_info: navigator.userAgent,
        sub_package: userpackage,
      });
      console.log("User Subscription Response: ", response);
      if (response.result) {
        // tagManagerEvents(EventsName.subscribe, "");
        console.log("User Subscribed Successfully");
        let user_Id = response.result;
        saveUserInfo(user_Id, msisdn, userpackage);
        setUserVerificationModalVisible(false);
        setUserProfileModalVisible(true);
        // navigate("/home", { state: { user_Id } });
        setOtp(["", "", "", ""]);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }
    } catch (error) {
      if (getErrorData(error) === "otp verification failed") {
        setErrorMsg(AppMessage.invalidOtp);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }
      console.log("Error while subscribing user: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginUser = async (_otp: string) => {
    console.log("I am in handle Subscribe User");
    try {
      let response = await LoginService({
        msisdn: msisdn,
        otp: _otp,
        device_info: navigator.userAgent,
        sub_package: userpackage,
      });
      console.log("User Login Response: ", response);
      if (response.result) {
        // tagManagerEvents(EventsName.login, "");
        console.log("User Logged In Successfully");
        let user_Id = response.result;
        saveUserInfo(user_Id, msisdn, userpackage);
        handleCheckUserProfileExistence(user_Id);
        // setUserDetailsModalVisible(true);
        // navigate("/home", { state: { user_Id } });
        setOtp(["", "", "", ""]);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }
    } catch (error) {
      if (getErrorData(error) === "otp verification failed") {
        setErrorMsg(AppMessage.invalidOtp);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }
      console.log("Error while logging in user: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveUserInfo = (userId: any, _msisdn: any, userPackage: any) => {
    setUserId(userId);
    localStorage.setItem("user_Id", userId);
    localStorage.setItem("msisdn", _msisdn);
    localStorage.setItem("subpackage", userPackage);
    if (userPackage.toLowerCase() === "post") {
      // setIsPrepaid(false);
      isPrepaid = false;
    }
  };

  const handleCheckUserProfileExistence = async (user_id: number) => {
    try {
      let response = await getProfileService({ user_id });
      if (
        response.result.name === null ||
        response.result.cnic === null ||
        response.result.email === null
      ) {
        setUserVerificationModalVisible(false);
        setUserProfileModalVisible(true);
      } else {
        setUserVerificationModalVisible(false);
        setPersonalData(response.result);
        // setUserDetailsModalVisible(true);
        // implement the api to get policy details again for isnominee and beneficiary checks
        handleRecheckPolicyDetails(user_id);
      }
    } catch (error) {
      console.log("Error while checking user profile: ", error);
    }
  };

  const onHandleSetUpUserProfile = async (data: any) => {
    console.log("User Details: ", data);
    try {
      setIsLoading(true);
      // let userId = localStorage.getItem("user_Id");
      let _data = {
        user_id: user_Id,
        name: data.firstName.trim() + " " + data.lastName.trim(),
        gender: data.gender,
        cnic: data.cnic.replaceAll("-", ""),
        email: data.email,
        dob: data.dob,
        residential_address: data.address,
      };
      let response = await updateProfileService(_data);
      setPersonalData(_data);
      if (response.result === "Success") {
        console.log("User Details Updated Successfully");
        handleRecheckPolicyDetails(user_Id ? parseInt(user_Id) : null);
        // implement the api to get policy details again for isnominee and beneficiary checks
        // setUserDetailsModalVisible(true);
      } else {
        console.log("Error while updating user details: ", response);
      }
    } catch (error) {
      setErrorMsg("Unable to update profile,please try again.");
      console.log("Error while updating user details: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecheckPolicyDetails = async (userId: number | null) => {
    try {
      console.log("UserID in recheck policy details: ", userId);
      let data = {};
      if (userId) {
        data = {
          policy_id: policyId,
          user_id: userId,
        };
      } else {
        data = {
          policy_id: policyId,
        };
      }
      let response: any = await getPolicyDetailsService(data);
      if (response.result) {
        console.log("Policy Details: ", response.result);
        filterPlansByPackage(response.result.plans);
        setPolicyDetails(response.result);
        setIsBeneficiary(response.result.policy.is_beneficiary);
        setIsMobilePolicy(response.result.policy.is_mobile_policy);
        setIsNominee(response.result.policy.is_nominee);
        setIsPurchased(response.result.policy.is_purchased);
        setUserProfileModalVisible(false);
        setPolicyDetailsModalVisible(true);
        // setInsuranceCategories(response.result);
      } else {
        console.log(AppMessage.noData);
        // Display a "No Data Found" Pop Up/ Toast over here
      }
    } catch (error) {
      console.warn("Get Policy Details Error: ", error);
      // console.log(AppMessage.initializationFailed);
    }
  };

  const onHandleProceedToPayment = async () => {
    try {
      console.log("Payment Plan Selected:::::", paymentPlan);
      // tagManagerParamsEvents(EventsName.paymentMethod, "", {
      //   title: paymentPlan,
      // });
      if (paymentPlan === "mobilebalance") {
        setOtp(["", "", "", ""]);
        sendPurchaseOtp();
      } else {
        setPolicyDetailsModalVisible(false);
        setIsPaymentSummaryModalVisible(true);
      }
    } catch (error) {}
  };

  const sendPurchaseOtp = async () => {
    // if (paymentPlan === "mobilebalance") {
    try {
      setIsOTPSending(true);
      setIsLoading(true);
      // let user_Id = localStorage.getItem("user_Id");
      let data = {
        user_id: user_Id,
        partner_id: partnerId,
      };
      let response = await sendPurchaseOtpService(data);
      if (response.result) {
        setTimer(30);
        setShowResendButton(false);
        // tagManagerEvents(EventsName.sendOtp, "");
        // response.result is the otp
        setIsPaymentSummaryModalVisible(false);
        setShowPaymentPlanPopup(false);
        setErrorMsg("");
        setShowPaymentOtpPopup(true);
      }
      setIsOTPSending(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsOTPSending(false);
      setErrorMsg(AppMessage.requestFailed);
      console.log("Error, Something went wrong, Please try again later");
    } finally {
      setIsLoading(false);
      setIsOTPSending(false);
    }
    // } else {
    //   console.log("Cannot proceed with this payment method");
    // }
  };

  const onHandlePressVerifyButton = async () => {
    try {
      let _otp = otp.join("");
      if (_otp.length !== 4) {
        setErrorMsg("Please enter a valid 4-digit OTP");
        return;
      }
      setErrorMsg("");
      console.log(
        "Texting data paasinhg",
        policyDetails.policy.is_beneficiary,
        "Beneficiary",
        beneficiariesData
      );

      setIsLoading(true);
      let data: any = {
        user_id: user_Id,
        policy_id: policyId,
        plan_id: coverId,
        payment_by: paymentPlan === "mobilebalance" ? null : "JAZZCASH",
        payment_method: paymentPlan === "mobilebalance" ? "DCB" : "WALLET",
        otp: _otp,
      };
      if (policyDetails.policy.is_beneficiary) {
        let beneficiaries = beneficiariesData.map((item: any, index: any) => {
          return {
            cnic: item.cnic.replaceAll("-", ""),
            name: item.name,
            contact_number: item.contact_number,
            relation_id: item.relation_id,
          };
        });
        data.beneficiaries = beneficiaries;
      }
      if (policyDetails.policy.is_mobile_policy) {
        data.handSetInfo = {
          imei_1: mobileData.imei_1,
          imei_2: mobileData.imei_2,
          phone_make: mobileData.phone_make,
          phone_model: mobileData.phone_model,
        };
      }
      if (policyDetails.policy.is_nominee) {
        data.nominee = {
          cnic: nomineeData.cnic.replaceAll("-", ""),
          name: nomineeData.name,
          contact_number: nomineeData.contact_number,
          relation_id: nomineeData.relation_id,
        };
      }
      console.warn("data::", policyDetails, data);
      let response = await purchasePolicyService(data);
      console.log("Payment Purchase resp===========>", response);
      if (response) {
        // Event Mark over here for purchase
        // tagManagerEvents(EventsName.policyPurchased, "");

        setShowPaymentOtpPopup(false);
        setIsLoading(false);
        if (paymentPlan === "mobilebalance") {
          setShowSuccessPopUp(true);
        } else {
          window.open(
            `https://fikrfree.com.pk/panel/fikrfree-panel/public/${"link-account"}?purchase_id=${
              response.result
            }`
          );
        }
      } else {
        setIsLoading(false);
      }
    } catch (error: any) {
      console.warn("err", error);
      setIsLoading(false);
      if (getErrorData(error) === "otp verification failed") {
        setErrorMsg(AppMessage.invalidOtp);
      } else if (getErrorData(error) === "policy already purchased") {
        setErrorMsg(AppMessage.alreadyPurchased);
      } else {
        setErrorMsg("Something went wrong please try again later");
      }
    }
  };

  const onHandleClosePartnersListModal = () => {
    setPartnersListModalVisible(false);
  };

  const onHandleClosePartnerPolciesModal = () => {
    setPartnerPoliciesModalVisible(false);
  };

  const onHandleClosePolicyDetailsModal = () => {
    setPolicyDetailsModalVisible(false);
  };

  const onHandleCloseUserDetailsModal = () => {
    setUserDetailsModalVisible(false);
  };

  const handleCloseSuccessPopUp = () => {
    setShowSuccessPopUp(false);
  };

  const onHandleCloseUserVerificationModal = () => {
    setUserVerificationModalVisible(false);
  };

  const onHandleCloseUserProfileModal = () => {
    setUserProfileModalVisible(false);
  };
  const onHandleClosePaymentPlanPopup = () => {
    setShowPaymentPlanPopup(false);
  };

  const onHandleClosePurchaseOTPPopUp = () => {
    setShowPaymentOtpPopup(false);
  };

  const onHandleClosePaymentSummaryPopUp = () => {
    setIsPaymentSummaryModalVisible(false);
    setShowPaymentPlanPopup(false);
  };

  const onHandleNavigateToProfileScreen = () => {
    try {
      navigate("/profile", { state: { personalData } });
    } catch (error) {}
  };

  const onNavigateToLanding = () => {
    try {
      tagManagerEvents(EventsName.LoginButton, "");
      navigate("/landing");
    } catch (error) {}
  };

  const onLogout = () => {
    console.log("Log Out Triggered");
    try {
      localStorage.removeItem("user_Id");
      localStorage.removeItem("msisdn");
      setUserId("");
      window.location.reload();
    } catch (error) {}
  };

  const resetStates = () => {
    coverId = 0;
    categoryId = 0;
    partnerId = 0;
    policyId = 0;
    policyPlans = [];
    selectedPolicy = [];
    selectedPlan = [];
    selectedCategory = [];
    selectedPartner = [];

    setPartnersList([]);
    setPartnerPolicies([]);
    setPolicyDetails([]);
    setIsNominee(false);
    setIsBeneficiary(false);
    setIsMobilePolicy(false);
    setIsPurchased(false);
    setMsisdn("");
    setOtp(["", "", "", ""]);
    setIsOTPSent(false);
    setIsLoading(false);
    setErrorMsg("");
    setSelectedCoverId(null);
    setIsConsentChecked(false);
    setPaymentPlan("jazzcash");
    setErrorMsg("");
    setActiveTab("Personal");
    setNoOfBeneficiaries(1);
    setExpandedStates([false]);
    setBeneficiariesData([
      {
        cnic: "",
        name: "",
        contact_number: "",
        relation_id: "",
      },
    ]);

    setNomineeData({
      cnic: "",
      name: "",
      contact_number: "",
      relation_id: "",
    });
    setMobileData({
      phone_make: "",
      phone_model: "",
      imei_1: "",
      imei_2: "",
    });
    setErrors({ nominee: {}, beneficiaries: [] });
  };

  return (
    <Home
      insuranceCategories={insuranceCategories}
      partnersList={partnersList}
      partnerPolicies={partnerPolicies}
      policyDetails={policyDetails}
      onCategoryPressed={onCategoryPressed}
      onPartnerListPressed={onPartnerListPressed}
      onPartnerPolicyPressed={onPartnerPolicyPressed}
      isPartnersListModalVisible={isPartnersListModalVisible}
      isPartnerPoliciesModalVisible={isPartnerPoliciesModalVisible}
      isPolicyDetailsModalVisible={isPolicyDetailsModalVisible}
      isUserVerificationModalVisible={isUserVerificationModalVisible}
      isUserProfileModalVisible={isUserProfileModalVisible}
      isUserDetailsModalVisible={isUserDetailsModalVisible}
      onHandleClosePartnersListModal={onHandleClosePartnersListModal}
      onHandleClosePartnerPolciesModal={onHandleClosePartnerPolciesModal}
      onHandleClosePolicyDetailsModal={onHandleClosePolicyDetailsModal}
      onHandleCloseUserDetailsModal={onHandleCloseUserDetailsModal}
      onHandleCloseUserVerificationModal={onHandleCloseUserVerificationModal}
      onHandleCloseUserProfileModal={onHandleCloseUserProfileModal}
      onHandleSelectCover={onHandleSelectCover}
      msisdn={msisdn}
      otp={otp}
      isOTPSent={isOTPSent}
      handleMsisdnChange={handleMsisdnChange}
      handleOnPressSendOTPButton={handleOnPressSendOTPButton}
      handleOTPSubmit={handleOTPSubmit}
      inputRefs={inputRefs}
      handlePINInputChange={handlePINInputChange}
      handlePINInput={handlePINInput}
      showResendButton={showResendButton}
      timer={timer}
      onHandleSetUpUserProfile={onHandleSetUpUserProfile}
      isLoading={isLoading}
      showDownloadPopup={showDownloadPopup}
      onShowDownloadPopup={onShowDownloadPopup}
      onHideDownloadPopup={onHideDownloadPopup}
      showPaymentPlanPopup={showPaymentPlanPopup}
      onHandleClosePaymentPlanPopup={onHandleClosePaymentPlanPopup}
      isLoggedIn={isLoggedIn}
      userId={user_Id}
      onNavigateToLanding={onNavigateToLanding}
      onLogout={onLogout}
      isNominee={isNominee}
      isBeneficiary={isBeneficiary}
      isMobilePolicy={isMobilePolicy}
      isPurchased={isPurchased}
      activeTab={activeTab}
      handleUpdateActiveTab={handleUpdateActiveTab}
      handleTogglePersonal={handleTogglePersonal}
      handleToggleMobile={handleToggleMobile}
      isPersonalOpen={isPersonalOpen}
      handleToggleNominee={handleToggleNominee}
      isNomineeOpen={isNomineeOpen}
      noOfBeneficiaries={noOfBeneficiaries}
      handleUpdateNoOfBeneficiaries={handleUpdateNoOfBeneficiaries}
      expandedStates={expandedStates}
      toggleExpandedState={toggleExpandedState}
      options={options}
      handleUpdateBeneficiariesData={handleUpdateBeneficiariesData}
      handleUpdateNomineeData={handleUpdateNomineeData}
      relationsData={relationsData}
      personalData={personalData}
      // onHandleSubmitUserDetailsPressed={onHandleSubmitUserDetailsPressed}
      handleUpdatePaymentMethod={handleUpdatePaymentMethod}
      paymentPlan={paymentPlan}
      onHandleProceedToPayment={onHandleProceedToPayment}
      onHandleClosePurchaseOTPPopUp={onHandleClosePurchaseOTPPopUp}
      showPaymentOtpPopup={showPaymentOtpPopup}
      onHandlePressVerifyButton={onHandlePressVerifyButton}
      showSuccessPopUp={showSuccessPopUp}
      handleCloseSuccessPopUp={handleCloseSuccessPopUp}
      onHandleNavigateToProfileScreen={onHandleNavigateToProfileScreen}
      handleRadioChange={handleRadioChange}
      handleSelectCover={handleSelectCover}
      handleUpdateConsent={handleUpdateConsent}
      selectedCoverId={selectedCoverId}
      isConsentChecked={isConsentChecked}
      errorMsg={errorMsg}
      isPrepaid={isPrepaid}
      policyPlans={policyPlans}
      isPaymentSummaryModalVisible={isPaymentSummaryModalVisible}
      onHandleClosePaymentSummaryPopUp={onHandleClosePaymentSummaryPopUp}
      selectedPolicy={selectedPolicy}
      selectedPlan={selectedPlan}
      _msisdn={localStorage.getItem("msisdn")}
      selectedCategory={selectedCategory}
      selectedPartner={selectedPartner}
      sendPurchaseOtp={sendPurchaseOtp}
      handleNomineeInputChange={handleNomineeInputChange}
      handleInputChange={handleInputChange}
      errors={errors}
      onSaveDataPressed={onSaveDataPressed}
      beneficiariesData={beneficiariesData}
      nomineeData={nomineeData}
      newUserData={newUserData}
      handleNewUserInputChange={handleNewUserInputChange}
      handleSaveUserData={handleSaveUserData}
      isOTPSending={isOTPSending}
      isMobileOpen={isMobileOpen}
      handleDeviceInfoInputChange={handleDeviceInfoInputChange}
      mobileData={mobileData}
    />
  );
};

export default HomeScreen;
