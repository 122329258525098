import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { formatCnic } from "../../utils/CommonFunctions";
import { tagManagerEvents } from "../../utils/GoogleTagManager";
import EventsName from "../../constants/EventsName";

interface UserDetailsPopupProps {
  isUserDetailsModalVisible: boolean;
  onHandleCloseUserDetailsModal: () => void;
  isNominee: boolean;
  isBeneficiary: boolean;
  isMobilePolicy: boolean;
  isPurchased: boolean;
  activeTab: string;
  handleUpdateActiveTab: (tab: string) => void;
  handleTogglePersonal: () => void;
  isPersonalOpen: boolean;
  handleToggleNominee: () => void;
  isNomineeOpen: boolean;
  noOfBeneficiaries: number;
  handleUpdateNoOfBeneficiaries: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  expandedStates: any;
  toggleExpandedState: (i: any) => void;
  options: any;
  handleUpdateBeneficiariesData: (data: any) => void;
  handleUpdateNomineeData: (data: any) => void;
  relationsData: any;
  personalData: any;
  msisdn: string;
  // onHandleSubmitUserDetailsPressed: (
  //   policyData: any,
  //   beneficiariesData: any
  // ) => void;
  errorMsg: string;
  handleNomineeInputChange: any;
  handleInputChange: any;
  errors: any;
  onSaveDataPressed: () => void;
  beneficiariesData: any;
  nomineeData: any;
  handleToggleMobile: () => void;
  isMobileOpen: boolean;
  handleDeviceInfoInputChange: (field: string, value: string) => void;
  mobileData: any;
}

const UserDetailsPopup = (props: UserDetailsPopupProps) => {
  return (
    <Modal
      show={props.isUserDetailsModalVisible}
      // show={true}
      onHide={props.onHandleCloseUserDetailsModal}
      className="user-profile-modal user-detail"
    >
      <Modal.Header closeButton>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-profile-model-content">
          <div className="user-detail-tabs">
            <button
              className={`tab-btn button1 ${
                props.activeTab === "Personal" ? "active" : ""
              }`}
              onClick={() => props.handleUpdateActiveTab("Personal")}
            >
              Personal
            </button>
            <>
              {props.isBeneficiary && (
                <button
                  className={`tab-btn button2 ${
                    props.activeTab === "Beneficiary" ? "active" : ""
                  }`}
                  onClick={() => props.handleUpdateActiveTab("Beneficiary")}
                >
                  Beneficiary
                </button>
              )}
              {props.isMobilePolicy && (
                <button
                  className={`tab-btn button2 ${
                    props.activeTab === "Mobile" ? "active" : ""
                  }`}
                  onClick={() => props.handleUpdateActiveTab("Mobile")}
                >
                  Handset Details
                </button>
              )}
            </>
          </div>
          {props.activeTab === "Personal" && (
            <>
              {/* Personal-Input-Group */}
              <div className="nominee-scrol">
                <div className="toggle-nominee-content">
                  <button
                    onClick={props.handleTogglePersonal}
                    className="nominee-button"
                  >
                    Personal
                    <FontAwesomeIcon
                      icon={props.isPersonalOpen ? faChevronUp : faChevronDown}
                      className="chvrn-down-style"
                    />
                  </button>
                  {props.isPersonalOpen && (
                    <>
                      <div className="nomine-fields">
                        <div className="input-form">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            CNIC
                          </label>
                          <input
                            disabled
                            type="email"
                            className="form-control"
                            // placeholder="37405-123567-2"
                            value={formatCnic(props.personalData?.cnic)}
                          />
                        </div>
                        <div className="input-form">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Full Name
                          </label>
                          <input
                            disabled
                            type="email"
                            className="form-control"
                            // placeholder="Haseeb"
                            value={props.personalData?.name}
                          />
                        </div>
                        <div className="input-form">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Email
                          </label>
                          <input
                            disabled
                            type="email"
                            className="form-control"
                            // placeholder="Haseeb"
                            value={props.personalData?.email}
                          />
                        </div>
                        <div className="input-form">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Date Of Birth
                          </label>
                          <input
                            disabled
                            type="email"
                            className="form-control"
                            // placeholder="Haseeb"
                            value={props.personalData?.dob}
                          />
                        </div>
                        <div className="input-form">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Contact Number
                          </label>
                          <input
                            disabled
                            type="email"
                            className="form-control"
                            // placeholder="034045770007"
                            value={localStorage.getItem("msisdn") as string}
                          />
                        </div>
                        <div className="input-form">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Residential Address
                          </label>
                          <input
                            disabled
                            type="email"
                            className="form-control"
                            // placeholder="Haseeb"
                            value={props.personalData?.residential_address}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Nomine-Input-Group */}
                {props.isNominee && (
                  <div className="toggle-nominee-content">
                    <button
                      onClick={props.handleToggleNominee}
                      className="nominee-button"
                    >
                      Nominee
                      <FontAwesomeIcon
                        icon={props.isNomineeOpen ? faChevronUp : faChevronDown}
                        className="chvrn-down-style"
                      />
                    </button>
                    {props.isNomineeOpen && (
                      <>
                        <div className="nomine-fields">
                          <div className="input-form">
                            <label htmlFor="nomineeCnic" className="form-label">
                              Nominee CNIC
                            </label>
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              placeholder="Enter Nominee's CNIC"
                              value={props.nomineeData.cnic}
                              onChange={(e) => {
                                const value = e.target.value;
                                let formatted = formatCnic(value);
                                props.handleNomineeInputChange(
                                  "cnic",
                                  formatted
                                );
                              }}
                            />
                          </div>
                          <div className="input-form">
                            <label
                              htmlFor="nomineeFullName"
                              className="form-label"
                            >
                              Nominee Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Nominee's Full Name"
                              value={props.nomineeData.name}
                              onChange={(e) => {
                                const value = e.target.value;
                                const regex = /^[A-Za-z\s]*$/;

                                if (regex.test(value)) {
                                  props.handleNomineeInputChange("name", value);
                                }
                              }}
                            />
                          </div>
                          <div className="input-form">
                            <label
                              htmlFor="nomineeContactNumber"
                              className="form-label"
                            >
                              Nominee Contact Number
                            </label>
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              placeholder="Enter Nominee's Contact Number"
                              value={props.nomineeData.contact_number}
                              onChange={(e) => {
                                const value = e.target.value;
                                let regex = new RegExp("^(3[0-9]*|)$");
                                if (regex.test(value)) {
                                  props.handleNomineeInputChange(
                                    "contact_number",
                                    value
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="input-form">
                            <label
                              htmlFor="nomineeRelationship"
                              className="form-label"
                            >
                              Nominee Relationship
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={props.nomineeData.relation_id}
                              onChange={(e) =>
                                props.handleNomineeInputChange(
                                  "relation_id",
                                  e.target.value
                                )
                              }
                            >
                              <option selected>Relationship</option>
                              {props.relationsData.map((relation: any) => (
                                <option key={relation.id} value={relation.id}>
                                  {relation.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          {props.activeTab === "Beneficiary" && (
            <>
              {/* Personal-Input-Group */}
              <div className="toggle-nominee-content">
                <button className="nominee-button">
                  <div className="input-form">
                    <label htmlFor="beneficiarySelect" className="form-label">
                      No. of Beneficiary
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="beneficiarySelect"
                      onChange={props.handleUpdateNoOfBeneficiaries}
                      value={props.noOfBeneficiaries}
                    >
                      {props.options.map((option: any) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </button>
              </div>
              {/* Nominee-Input-Group */}
              <div className="nominee-scrol">
                {Array.from({ length: props.noOfBeneficiaries }, (_, i) => (
                  <div key={i} className="toggle-nominee-content">
                    <button
                      onClick={() => props.toggleExpandedState(i)}
                      className="nominee-button"
                    >
                      Beneficiary {i + 1}
                      <FontAwesomeIcon
                        icon={
                          props.expandedStates[i] ? faChevronUp : faChevronDown
                        }
                        className="chvrn-down-style"
                      />
                    </button>
                    {props.expandedStates[i] && (
                      <>
                        <div className="nomine-fields">
                          <div className="input-form">
                            <label
                              htmlFor={`beneficiaryCnic${i + 1}`}
                              className="form-label"
                            >
                              Beneficiary CNIC
                            </label>
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              placeholder="Enter Beneficiary's CNIC"
                              id={`beneficiaryCnic${i + 1}`}
                              value={props.beneficiariesData[i]?.cnic}
                              onChange={(e) => {
                                const value = e.target.value;

                                let formatted = formatCnic(value);
                                props.handleInputChange(i, "cnic", formatted);
                              }}
                            />
                          </div>
                          <div className="input-form">
                            <label
                              htmlFor="beneficiaryFullName"
                              className="form-label"
                            >
                              Beneficiary Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Beneficiary's Full Name"
                              value={props.beneficiariesData[i]?.name}
                              onChange={(e) => {
                                const value = e.target.value;
                                const regex = /^[A-Za-z\s]*$/;
                                if (regex.test(value)) {
                                  props.handleInputChange(
                                    i,
                                    "name",
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="input-form">
                            <label
                              htmlFor="beneficiaryContactNumber"
                              className="form-label"
                            >
                              Beneficiary Contact Number
                            </label>
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              placeholder="Enter Beneficiary's Contact Number"
                              value={props.beneficiariesData[i]?.contact_number}
                              onChange={(e) => {
                                const value = e.target.value;
                                //   // Regex to allow only numbers
                                let regex = new RegExp("^(3[0-9]*|)$");

                                if (regex.test(value)) {
                                  props.handleInputChange(
                                    i,
                                    "contact_number",
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="input-form">
                            <label
                              htmlFor="beneficiaryRelationship"
                              className="form-label"
                            >
                              Beneficiary Relationship
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={props.beneficiariesData[i]?.relation_id}
                              onChange={(e) =>
                                props.handleInputChange(
                                  i,
                                  "relation_id",
                                  e.target.value
                                )
                              }
                            >
                              <option value="default" selected>
                                Relationship
                              </option>
                              {props.relationsData.map((relation: any) => (
                                <option key={relation.id} value={relation.id}>
                                  {relation.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}

          {props.activeTab === "Mobile" && (
            <>
              {/* Nominee-Input-Group */}
              <div className="nominee-scrol">
                {/* {Array.from({ length: props.noOfBeneficiaries }, (_, i) => ( */}
                <div className="toggle-nominee-content">
                  <button
                    onClick={props.handleToggleMobile}
                    className="nominee-button"
                  >
                    Handset Insurance
                    <FontAwesomeIcon
                      icon={props.isMobileOpen ? faChevronUp : faChevronDown}
                      className="chvrn-down-style"
                    />
                  </button>
                  {props.isMobileOpen && (
                    <>
                      <div className="nomine-fields">
                        <div className="input-form">
                          <label
                            // htmlFor={`beneficiaryCnic${i + 1}`}
                            className="form-label"
                          >
                            Phone Make
                          </label>
                          <input
                            type="text"
                            maxLength={25}
                            className="form-control"
                            placeholder="Enter Phone's Make"
                            // id={`beneficiaryCnic${i + 1}`}
                            value={props.mobileData.phone_make}
                            onChange={(e) => {
                              const noSpecialCharsRegex = /^[a-zA-Z0-9 ]*$/;
                              const value = e.target.value;
                              if (noSpecialCharsRegex.test(value))
                                props.handleDeviceInfoInputChange(
                                  "phone_make",
                                  value
                                );
                            }}
                          />
                        </div>
                        <div className="input-form">
                          <label
                            htmlFor="beneficiaryFullName"
                            className="form-label"
                          >
                            Phone Model
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone's Model"
                            maxLength={25}
                            value={props.mobileData.phone_model}
                            onChange={(e) => {
                              const noSpecialCharsRegex = /^[a-zA-Z0-9 ]*$/;
                              const value = e.target.value;
                              if (noSpecialCharsRegex.test(value))
                                props.handleDeviceInfoInputChange(
                                  "phone_model",
                                  value
                                );

                              // let formatted = formatCnic(value);
                            }}
                          />
                        </div>
                        <div className="input-form">
                          <label
                            htmlFor="beneficiaryContactNumber"
                            className="form-label"
                          >
                            IMEI 1
                          </label>
                          <input
                            type="text"
                            maxLength={15}
                            className="form-control"
                            placeholder="Enter IMEI 1"
                            value={props.mobileData.imei_1}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Remove any non-numeric characters
                              props.handleDeviceInfoInputChange(
                                "imei_1",
                                value
                              );
                            }}
                          />
                        </div>

                        <div className="input-form">
                          <label
                            htmlFor="beneficiaryContactNumber"
                            className="form-label"
                          >
                            IMEI 2
                          </label>
                          <input
                            type="text"
                            maxLength={15}
                            className="form-control"
                            placeholder="Enter IMEI 2 (Optional)"
                            value={props.mobileData.imei_2}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Remove any non-numeric characters
                              props.handleDeviceInfoInputChange(
                                "imei_2",
                                value
                              );
                            }}
                          />
                        </div>
                        {/* <div className="check-box">
                          <input
                            type="checkbox"
                            // checked={props.isConsentChecked}
                            // onClick={props.handleUpdateConsent}
                          />
                          <label htmlFor="vehicle1">
                            {" "}
                            Consider this device to be my primary device.
                          </label>
                        </div> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {props.errorMsg && <div className="error-msg">{props.errorMsg}</div>}
          <div className="d-btn">
            <button
              onClick={() => {
                props.onSaveDataPressed();
                tagManagerEvents(EventsName.SubmitToPaymentMethod, "");
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserDetailsPopup;
