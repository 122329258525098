import React, { useEffect, useRef, useState } from "react";
import {
  checkOperatorService,
  generateBimaSehatOTPService,
  activateBimaSehatMobileInsuranceService,
  getHeaderEnrichmentService,
  getPlanService,
  bimaActivatePlanService,
} from "../services/LandingService";
import { useNavigate } from "react-router-dom";
import { tagManagerEvents } from "../utils/GoogleTagManager";
import LandingSehatBima from "../components/LandingBima";
import { getErrorData } from "../utils/CommonFunctions";
import AppMessage from "../constants/AppMessage";

interface planType {
  id: number;
  title: string;
  charging: number;
  type: string;
  description: string;
  cover_benefit: number;
  sub_package: string;
}
type Props = {
  token: string;
};
let initialHEMsisdn: String = "";

const LandingSehatBimaScreen = (props: Props) => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [msisdn, setMsisdn] = useState<string>("");
  const [showOtp, setShowOtp] = useState<boolean>(false);
  // isLoading for all loading button and functionality
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // loginLoading for only Login loading button and functionality
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  //`planData` is for storing and managing the details of the selected plan, such as charging ,rate, type, description, and other related information.
  const [planData, setPlanData] = useState<planType | null>(null);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [counter, setCounter] = useState<number>(0); // Counter state to manage timer

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus(); // Focus on the first input
    }
  }, []);

  // useEffect hook to manage the countdown when the timer starts
  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer); // Cleanup on component unmount or counter reset
    }
  }, [counter]);

  useEffect(() => {
    let userId = localStorage.getItem("user_Id");
    if (userId) {
      navigate("/home");
    } else {
      if (props.token) {
        getHeaderEnrichment();
        getPlans();
      }
    }
  }, [props.token]);

  //   He Flow
  const getHeaderEnrichment = async () => {
    try {
      let response = await getHeaderEnrichmentService();
      if (response.result) {
        let _num = response.result.slice(-10);
        setMsisdn(_num);
        initialHEMsisdn = _num;
      }
    } catch (error) {
      setErrorMsg("Something went wrong");
      console.log(" Error: ", error);
    }
  };

  // Get Plans
  const getPlans = async () => {
    try {
      let response = await getPlanService();
      if (response.result) {
        setPlanData(response.result);
      }
    } catch (error) {
      setErrorMsg("Something went wrong");
      console.log(" Error: ", error);
    }
  };

  // Function to  resend click
  const onResendClick = async () => {
    try {
      setOtp(["", "", "", ""]);
      setIsLoading(true);

      let response = await generateBimaSehatOTPService({ msisdn });

      setIsLoading(false);
      setErrorMsg("");
      setCounter(30);
    } catch (error) {
      setIsLoading(false);

      setErrorMsg("Something went wrong.Please try again");
    }
  };

  const onMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let regex = new RegExp("^(3[0-9]*|)$");

    if (regex.test(input)) {
      setMsisdn(input);
    }
  };

  const onPressSendOTPButton = async () => {
    setErrorMsg("");
    if (msisdn.length === 10) {
      checkOperator();
    } else {
      setErrorMsg("Please enter a valid 10-digit phone number");
    }
  };

  const checkOperator = async () => {
    try {
      setIsLoading(true);
      let response = await checkOperatorService({ msisdn });

      if (
        response.result.isOther === false &&
        response.result.package.toLowerCase() === "pre"
      ) {
        sendOTP();
      } else {
        setIsLoading(false);

        if (response.result.isOther) {
          setErrorMsg(AppMessage.otherOperator);
        } else if (response.result.package.toLowerCase() === "post") {
          setErrorMsg(AppMessage.onlyForPrepaid);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong.Please try again");
      console.log("Error while sending OTP: ", error);
    }
  };

  const sendOTP = async () => {
    try {
      let response = await generateBimaSehatOTPService({ msisdn });
      if (msisdn === initialHEMsisdn) {
        setOtp([...response.result]);
      }
      tagManagerEvents("Handset_Get_Otp", utmSource);
      setShowOtp(true);
      setErrorMsg("");
      setCounter(30);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowOtp(false);
      setErrorMsg("Something went wrong.Please try again");
    }
  };

  //   Otp
  const onPINInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      onCodeChange(index, value);
      if (index < inputRefs.current.length - 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const onCodeChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...otp];
      newCode[index] = value;
      setOtp(newCode);
    }
  };

  const onPINInput = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && otp[index] !== "") {
      if (index >= 0) {
        onCodeChange(index, "");
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          inputRefs.current[index]?.focus();
        }
      }
    }
  };

  const onOTPSubmit = () => {
    let _otp = otp.join("");
    if (_otp.length === 4) {
      setErrorMsg("");
      loginUser(_otp);
    } else {
      setErrorMsg("Please enter a valid 4-digit OTP.");
    }
  };

  const loginUser = async (_otp: string) => {
    try {
      setLoginLoading(true);
      let data = {
        msisdn: msisdn,
        otp: _otp,
        plan_id: 6,
      };
      let response = await bimaActivatePlanService(data);

      setLoginLoading(false);
      tagManagerEvents("BimaSehat_Purchased", utmSource);

      navigate("/home");
    } catch (error: any) {
      setLoginLoading(false);

      if (getErrorData(error) === "policy already purchased") {
        setErrorMsg(AppMessage.alreadyPurchased);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }

      console.log("Error while checking user: ", error);
    }
  };

  const backButton = () => {
    setOtp(["", "", "", ""]);
    setErrorMsg("");
    setShowOtp(false);
  };

  return (
    <LandingSehatBima
      msisdn={msisdn}
      errorMsg={errorMsg}
      isLoading={isLoading}
      onMsisdnChange={onMsisdnChange}
      showOtp={showOtp}
      onResendClick={onResendClick}
      counter={counter}
      otp={otp}
      inputRefs={inputRefs}
      onPINInputChange={onPINInputChange}
      onOTPSubmit={onOTPSubmit}
      onPINInput={onPINInput}
      backButton={backButton}
      onPressSendOTPButton={onPressSendOTPButton}
      loginLoading={loginLoading}
      planData={planData}
    />
  );
};

export default LandingSehatBimaScreen;
