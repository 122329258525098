const AppMessage = {
  IncompleteField: "This Field is Required",
  cnicLength: "CNIC is not valid. Enter a valid 13 Digits CNIC",
  msisdnLength:
    "Mobile Number is not valid. Enter a valid 10 digits mobile number",
  otherOperator:
    "Service is only available for Jazz Users. Please enter a working Jazz Number",
  invalidOtp:
    "OTP is not valid. Please enter a valid OTP received on your mobile number",
  invalidEmail: "Please enter a valid email address",
  requestFailed: "Something went wrong. Please try again later",
  noInternet:
    "No internet connection. Please connect to a stable network and try again",
  initializationFailed:
    "Something went wrong. Please check your network connection and",
  acceptTerms: "Please accept the terms and conditions first to continue",
  selectPlan: "Please select plan to continue",
  nomineeIncompleteData: "All fields are required for nominee",
  benefIncompleteData: "Complete details for beneficiaries is required",
  selectDropdownItem: "Select an item to continue",
  nomineeIncompleteInfo: "Complete details for nominee is required",
  noData: "No data found",
  noFile: "Please upload atleast one document",
  noBeneficiary: "Please select atleast one beneficiary",
  fileSize: "File must be less than or equal to 1 MB.",
  alreadyPurchased: "Dear Customer, you have already purchased this policy.",
  nothingToUpdate: "Nothing to update",
  imageUploadSuccess: "Image updated successfully!",
  profileUpdateSuccess: "Profile updated successfully!",
  noAccount: "Please Enter Your Account Number First",
  noClaims: "No claims yet",
  searchTextLimit: "Please enter atleast three characters to search",
  onlyForPrepaid: "Sorry, this plan is only available for prepaid users.",
  fileAdded: "File already added",
  onlyForPostpaid: "Sorry, this plan is only available for postpaid users.",
};
export default AppMessage;
