// import React from 'react'
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/insuranceInfoPopup.css";
import detaillogo from "../../Assets/imgs/detail-modal-logo.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import tooltipimage from "../../Assets/imgs/tooltip-img.webp";
import emptycontainer from "../../Assets/imgs/emptyContainer.webp";
import { tagManagerEvents } from "../../utils/GoogleTagManager";
import EventsName from "../../constants/EventsName";

interface InsuranceDetailProps {
  policyDetails: any;
  isPolicyDetailsModalVisible: boolean;
  onHandleClosePolicyDetailsModal: () => void;
  onHandleSelectCover: (coverId: number) => void;
  handleRadioChange: (
    coverId: number,
    coverDetails: any,
    policyDetails: any
  ) => void;
  handleSelectCover: () => void;
  handleUpdateConsent: () => void;
  selectedCoverId: string;
  isConsentChecked: boolean;
  errorMsg: string;
  isPrepaid: boolean;
  policyPlans: any;
  isLoading: boolean;
}

const createMarkup = (data: string) => {
  return { __html: data };
};

const InsuranceDetail = (props: InsuranceDetailProps) => {
  useEffect(() => {}, [props.policyDetails]);

  const getBackImage = (index: number) => {
    const images = [
      require("../../Assets/imgs/card-bg-1.webp"),
      require("../../Assets/imgs/card-bg-2.webp"),
      require("../../Assets/imgs/card-bg-3.webp"),
      require("../../Assets/imgs/card-bg-4.webp"),
      require("../../Assets/imgs/card-bg-5.webp"),
    ];
    return `url(${images[index % images.length]})`;
  };

  return (
    <>
      <Modal
        show={props.isPolicyDetailsModalVisible}
        size="xl"
        className="detail-modal"
        onHide={props.onHandleClosePolicyDetailsModal}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}

          <div className="detail-logo">
            {/* <img src={detaillogo} alt="" /> */}
            {props.policyDetails.policy?.title}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="detail-content">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="detail-left">
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      props.policyDetails.policy?.description
                    )}
                  ></div>
                  <div className="ins_heading">Benefits</div>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      props.policyDetails.policy?.benefits
                    )}
                  ></div>
                  {}
                  {/* {props.policyDetails.policy?.description} */}
                  {/* {props.policyDetails.policy?.benefits} */}
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12">
                <div className="detail-right">
                  <div className="detail-title">Select Cover</div>

                  <div className="row">
                    {props.policyPlans.length > 0 ? (
                      props.policyPlans?.map((item: any, index: number) => (
                        <div className="col-lg-6 col-md-12 col-12">
                          <div
                            className="detail-card a"
                            style={{
                              backgroundImage: getBackImage(index),
                            }}
                          >
                            <div className="detail-input">
                              <div className="radio">
                                <input
                                  type="radio"
                                  id={`cover-${item.id}`}
                                  name="cover"
                                  value={item.id}
                                  onChange={() => {
                                    props.handleRadioChange(
                                      item.id,
                                      item,
                                      props.policyDetails.policy
                                    );
                                    tagManagerEvents(
                                      EventsName.SubSubProductClick,
                                      ""
                                    );
                                  }}
                                  checked={props.selectedCoverId === item.id}
                                />
                                <label htmlFor="html">{item.title}</label>
                              </div>
                              <div className="plan_tooltip">
                                <img src={tooltipimage} alt="" />
                                <div className="plan_tooltiptext">
                                  <div className="tooltip-title">Benefits</div>
                                  <div className="plan_tooltiptext-desc">
                                    {item.description}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="detail-price">
                              <div className="charge">
                                {item.type === "DAILY" ? "Daily" : "Weekly"}{" "}
                                Charges
                              </div>

                              <div className="d-price">
                                <span>{item.charging}</span> Rs.
                              </div>
                            </div>
                            <hr></hr>
                            <div className="detail-price ">
                              <div className="charge">Cover Benefit</div>

                              <div className="d-price">
                                upto <span>{item.cover_benefit}</span> Rs.
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="detail-icon">
                        <img src={emptycontainer} alt="" />
                        <p>Coming Soon</p>
                      </div>
                    )}

                    <div className="col-md-12">
                      {/* <div className="term-link">
                          <a href="#">Terms & Conditions</a>
                        </div> */}

                      {props.policyPlans.length > 0 && (
                        <div className="selection-section">
                          <div className="check-box">
                            <input
                              type="checkbox"
                              checked={props.isConsentChecked}
                              onClick={() => {
                                props.handleUpdateConsent();
                                tagManagerEvents(
                                  EventsName.TermsAndConditionsClick,
                                  ""
                                );
                              }}
                            />
                            <label htmlFor="vehicle1">
                              {" "}
                              I consent buying the product and agree you applied
                              Terms & Conditions
                            </label>
                          </div>
                          <div className="d-btn">
                            <button
                              onClick={() => {
                                props.handleSelectCover();
                                tagManagerEvents(
                                  EventsName.NextToAddDetails,
                                  ""
                                );
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    {props.errorMsg ? (
                      <p className="error-msg">{props.errorMsg}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InsuranceDetail;
