import React from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/UserProfilePopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { formatCnic } from "../../utils/CommonFunctions";
import moment from "moment";

type Props = {
  isUserProfileModalVisible: boolean;
  closeUserProfileModal: () => void;
  isLoading: boolean;
  errorMsg: string;
  OnPressSendOTPButton: () => void;

  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  inputValue: {
    name: string;
    cnic: string;
    phone_make: string;
    phone_model: string;
    imei_1: string;
    imei_2: string;
  };
  inputError: {
    name: boolean;
    cnic: boolean;
    phone_make: boolean;
    phone_model: boolean;
    imei_1: boolean;
    imei_2: boolean;
  };
  errorText: {
    name: string;
    cnic: string;
    phone_make: string;
    phone_model: string;
    imei_1: string;
    imei_2: string;
  };
};

const UserProfileWaddaPopup = (props: Props) => {
  return (
    <>
      <Modal
        show={props.isUserProfileModalVisible}
        onHide={props.closeUserProfileModal}
        className="user-profile-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user-profile-modal-content">
            <div className="m-heading">Personal Details</div>
            <div className="m-text">Enter your personal information</div>

            {/* Name Input */}
            <div className="input-form">
              <label className="form-label">Name</label>
              <input
                type="text"
                name="name"
                value={props.inputValue.name}
                onChange={props.onInputChange}
                className={`form-control ${
                  props.inputError.name ? "is-invalid" : ""
                }`}
                placeholder="Enter First Name Here"
              />
              {props.inputError.name && (
                <div className="error-msg" style={{ textAlign: "left" }}>
                  {props.errorText.name}
                </div>
              )}
            </div>

            {/* CNIC Input */}
            <div className="input-form">
              <label className="form-label">CNIC</label>
              <input
                type="text"
                maxLength={15}
                name="cnic"
                value={props.inputValue.cnic}
                onChange={props.onInputChange}
                className={`form-control ${
                  props.inputError.cnic ? "is-invalid" : ""
                }`}
                placeholder="xxxxx-xxxxxxx-x"
              />
              {props.inputError.cnic && (
                <div className="error-msg" style={{ textAlign: "left" }}>
                  {props.errorText.cnic}
                </div>
              )}
            </div>

            <div className="m-heading mt-2">Handset Information</div>
            <div className="m-text">Enter your Handset information</div>

            {/* Phone Make Input */}
            <div className="input-form">
              <label className="form-label">Phone Make</label>
              <input
                type="text"
                name="phone_make"
                value={props.inputValue.phone_make}
                onChange={props.onInputChange}
                className={`form-control ${
                  props.inputError.phone_make ? "is-invalid" : ""
                }`}
                placeholder="Enter Phone Make"
              />
            </div>

            {/* Phone Model Input */}
            <div className="input-form">
              <label className="form-label">Phone Model</label>
              <input
                type="text"
                name="phone_model"
                value={props.inputValue.phone_model}
                onChange={props.onInputChange}
                className={`form-control ${
                  props.inputError.phone_model ? "is-invalid" : ""
                }`}
                placeholder="Enter Phone Model"
              />
            </div>

            {/* IMEI 1 Input */}
            <div className="input-form">
              <label className="form-label">IMEI 1</label>
              <input
                type="text"
                name="imei_1"
                maxLength={15}
                value={props.inputValue.imei_1}
                onChange={props.onInputChange}
                className={`form-control ${
                  props.inputError.imei_1 ? "is-invalid" : ""
                }`}
                placeholder="Enter IMEI 1"
              />
              {props.inputError.imei_1 && (
                <div className="error-msg" style={{ textAlign: "left" }}>
                  {props.errorText.imei_1}
                </div>
              )}
            </div>

            {/* IMEI 2 Input */}
            <div className="input-form">
              <label className="form-label">IMEI 2</label>
              <input
                type="text"
                name="imei_2"
                value={props.inputValue.imei_2}
                maxLength={15}
                onChange={props.onInputChange}
                className={`form-control ${
                  props.inputError.imei_2 ? "is-invalid" : ""
                }`}
                placeholder="Enter IMEI 2"
              />
              {props.inputError.imei_2 && (
                <div className="error-msg" style={{ textAlign: "left" }}>
                  {props.errorText.imei_2}
                </div>
              )}
            </div>

            {/* Error message display */}
            {props.errorMsg && (
              <div className="error-msg">{props.errorMsg}</div>
            )}

            {/* Submit button */}
            {props.isLoading ? (
              <div className="d-btn">
                <button className="w-100">Sending OTP</button>
              </div>
            ) : (
              <div className="d-btn" onClick={props.OnPressSendOTPButton}>
                <button className="w-100">Get OTP</button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserProfileWaddaPopup;
