import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../../styles/UserPaymentPopup.css";
import paymentImg1 from "../../Assets/imgs/payment-method-popup-img/payment-img-1.webp";
import paymentImg2 from "../../Assets/imgs/payment-method-popup-img/payment-img-2.webp";
import paymentImg3 from "../../Assets/imgs/payment-method-popup-img/payment-img-3.webp";
import { tagManagerEvents } from "../../utils/GoogleTagManager";
import EventsName from "../../constants/EventsName";

interface UserPaymentPopupProps {
  showPaymentPlanPopup: boolean;
  onHandleClosePaymentPlanPopup: () => void;
  handleUpdatePaymentMethod: (method: string) => void;
  paymentPlan: string;
  onHandleProceedToPayment: () => void;
  isLoading: boolean;
  errorMsg: string;
}

const UserPaymentPopup = (props: UserPaymentPopupProps) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);

  // State to manage the selected payment method
  const [paymentMethod, setPaymentMethod] = useState("jazzCash");

  const handlePaymentMethodChange = (method: string) => {
    if (isLoading) return;
    setPaymentMethod(method);
  };

  return (
    <>
      <Modal
        show={props.showPaymentPlanPopup}
        onHide={() => {
          setIsLoading(false);
          props.onHandleClosePaymentPlanPopup();
        }}
        className="user-payment-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
            Payment Method
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-methods">
            <div className="p-method-img-text">
              <div className="payment-img">
                <img src={paymentImg1} alt="Jazz Cash" width={47} height={47} />
              </div>
              <div className="payment-text">
                <div className="p-method-name">Jazz Cash</div>
                <div className="p-method-detail">
                  Your account will be charged daily.
                </div>
              </div>
            </div>
            <div className="payment-select">
              <input
                type="radio"
                id="jazzcash"
                name="paymentMethod"
                value="jazzcash"
                checked={props.paymentPlan === "jazzcash"}
                // onChange={() => handlePaymentMethodChange("jazzCash")}
                onChange={() => {
                  tagManagerEvents(EventsName.JazzCashPaymentMethod, "");
                  props.handleUpdatePaymentMethod("jazzcash");
                }}
              />
            </div>
          </div>
          <div className="payment-methods">
            <div className="p-method-img-text">
              <div className="payment-img">
                <img
                  src={paymentImg2}
                  alt="Mobile Balance"
                  width={47}
                  height={47}
                />
              </div>
              <div className="payment-text">
                <div className="p-method-name">Mobile Balance</div>
                <div className="p-method-detail">
                  Your balance will be debited daily.
                </div>
              </div>
            </div>
            <div className="payment-select">
              <input
                type="radio"
                id="mobilebalance"
                name="paymentMethod"
                value="mobilebalance"
                checked={props.paymentPlan === "mobilebalance"}
                // onChange={() => handlePaymentMethodChange("mobileBalance")}
                onChange={() => {
                  tagManagerEvents(EventsName.MobileBalancePaymentMethod, "");
                  props.handleUpdatePaymentMethod("mobilebalance");
                }}
              />
            </div>
          </div>
          <div className="payment-methods">
            <div className="p-method-img-text">
              <div className="payment-img">
                <img
                  src={paymentImg3}
                  alt="Debit / Credit Card"
                  width={47}
                  height={47}
                />
              </div>
              <div className="payment-text">
                <div className="p-method-name p-method-third">
                  Debit / Credit Card
                </div>
                <div className="p-method-detail">Coming Soon</div>
              </div>
            </div>
            <div className="payment-select">
              <input
                type="radio"
                disabled
                id="debitCreditCard"
                name="paymentMethod"
                value="debitCreditCard"
                checked={paymentMethod === "debitCreditCard"}
                onChange={() => handlePaymentMethodChange("debitCreditCard")}
              />
            </div>
          </div>
          {props.isLoading ? (
            <div className="d-btn">
              <button>Sending OTP</button>
            </div>
          ) : (
            <div className="d-btn">
              <button
                onClick={() => {
                  tagManagerEvents(EventsName.SubscriptionOTP, "");
                  props.onHandleProceedToPayment();
                }}
              >
                {/* {props.isLoading ? "Sending OTP" : "Next"} */}
                Next
              </button>
            </div>
          )}
          {props.errorMsg && <p className="error-msg">{props.errorMsg}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserPaymentPopup;
