import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import Aos from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Assets/imgs/logo.webp";
import bannerImg from "../Assets/imgs/hero-img.webp";
import bima from "../Assets/imgs/bima.webp";
import waada from "../Assets/imgs/waada.webp";
import efu from "../Assets/imgs/efu.webp";
import webdoc from "../Assets/imgs/webdocicon.webp";
import icon1 from "../Assets/imgs/icons-1.webp";
import icontwo from "../Assets/imgs/icon-2.webp";
import iconthree from "../Assets/imgs/icon-3.webp";
import iconfour from "../Assets/imgs/icon-4.webp";
import iconfive from "../Assets/imgs/icon-5.webp";
import iconsix from "../Assets/imgs/icon-6.webp";
import iconseven from "../Assets/imgs/icon-7.webp";
import iconeight from "../Assets/imgs/icon-8.webp";
import claim from "../Assets/imgs/calim-img.webp";
import tick from "../Assets/imgs/check-icon.webp";
import buypolicy from "../Assets/imgs/buy-policy.webp";
import downloadImg from "../Assets/imgs/download-adpp.webp";
import downloadImgmob from "../Assets/imgs/download-mb.webp";
import facebook from "../Assets/imgs/fb-icon.webp";
import insta from "../Assets/imgs/insta-icon.webp";
import Dropdown from "react-bootstrap/Dropdown";
import profileVector from "../Assets/imgs/profileVector.webp";
import logouticon from "../Assets/imgs/logouticon.webp";
import userAvatar from "../Assets/imgs/user-avatar.webp";
// import linkdin from "../Assets/imgs/linkedin-icon.webp";
// import twitter from "../Assets/imgs/twitter-icon.webp";
import leftside from "../Assets/imgs/leftside-bg.webp";
import menubar from "../Assets/imgs/menubar.webp";
import closebtn from "../Assets/imgs/cross-icon.webp";
// modal
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import popUpRightImg from "../Assets/imgs/popup-bg.webp";
import popUpLogo from "../Assets/imgs/popup-img.webp";
// modal

import mbima from "../Assets/imgs/modallogos/BIMA.webp";
import mefu from "../Assets/imgs/modallogos/efu.webp";
import mwaada from "../Assets/imgs/modallogos/Waada.webp";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";
// swiper

// Import Pop Up Modals Over here
import InsuranceDetail from "./popups/InsuranceDetail";
import InsuranceProvider from "./popups/insurranceProvider";
import InsurranceSlider from "./popups/InsurranceSlider";
import UserVerificationPopUp from "./popups/UserVerificationPopUp";
import UserProfilePopup from "./popups/UserProfilePopup";
import UserDetailsPopup from "./popups/UserDetailsPopup";
import UserPaymentPopup from "./popups/UserPaymentPopup";
import UserPaymentSummary from "./popups/UserPaymentSummary";
import UserSuccesPopup from "./popups/UserSuccesPopup";
import PurchaseOTPPopUp from "./popups/PurchaseOTPPopUp";
import { imageUrl } from "../utils/Config";
import { tagManagerEvents } from "../utils/GoogleTagManager";
import EventsName from "../constants/EventsName";

interface HomeScreenProps {
  insuranceCategories: any;
  partnersList: any;
  partnerPolicies: any;
  policyDetails: any;
  onCategoryPressed: (id: number, category: any) => void;
  onPartnerListPressed: (partnerID: number, partnerData: any) => void;
  onPartnerPolicyPressed: (policy_id: number, policyTitle: string) => void;
  isPartnersListModalVisible: boolean;
  isPartnerPoliciesModalVisible: boolean;
  isPolicyDetailsModalVisible: boolean;
  isUserVerificationModalVisible: boolean;
  isUserProfileModalVisible: boolean;
  isUserDetailsModalVisible: boolean;
  onHandleClosePartnersListModal: () => void;
  onHandleClosePartnerPolciesModal: () => void;
  onHandleClosePolicyDetailsModal: () => void;
  onHandleCloseUserDetailsModal: () => void;
  onHandleCloseUserVerificationModal: () => void;
  onHandleCloseUserProfileModal: () => void;
  onHandleSelectCover: (coverId: number) => void;
  msisdn: string;
  otp: string[];
  isOTPSent: boolean;
  handleMsisdnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnPressSendOTPButton: () => void;
  handleOTPSubmit: () => void;
  inputRefs: any;
  handlePINInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePINInput: (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  showResendButton: boolean;
  timer: number;
  onHandleSetUpUserProfile: (data: any) => void;
  isLoading: boolean;
  showDownloadPopup: boolean;
  onShowDownloadPopup: () => void;
  onHideDownloadPopup: () => void;
  showPaymentPlanPopup: boolean;
  onHandleClosePaymentPlanPopup: () => void;
  isLoggedIn: boolean;
  userId: any;
  onNavigateToLanding: () => void;
  onLogout: () => void;
  isNominee: boolean;
  isBeneficiary: boolean;
  isMobilePolicy: boolean;
  isPurchased: boolean;
  activeTab: string;
  handleUpdateActiveTab: (tab: string) => void;
  handleTogglePersonal: () => void;
  isPersonalOpen: boolean;
  handleToggleNominee: () => void;
  isNomineeOpen: boolean;
  noOfBeneficiaries: number;
  handleUpdateNoOfBeneficiaries: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  expandedStates: any;
  toggleExpandedState: (i: any) => void;
  options: any;
  handleUpdateBeneficiariesData: (data: any) => void;
  handleUpdateNomineeData: (data: any) => void;
  relationsData: any;
  personalData: any;
  // onHandleSubmitUserDetailsPressed: (
  //   policyData: any,
  //   beneficiariesData: any
  // ) => void;
  handleUpdatePaymentMethod: (method: string) => void;
  paymentPlan: string;
  onHandleProceedToPayment: () => void;
  onHandleClosePurchaseOTPPopUp: () => void;
  showPaymentOtpPopup: boolean;
  onHandlePressVerifyButton: () => void;
  showSuccessPopUp: boolean;
  handleCloseSuccessPopUp: () => void;
  onHandleNavigateToProfileScreen: () => void;
  handleRadioChange: (
    coverId: number,
    coverDetails: any,
    policyDetails: any
  ) => void;
  handleSelectCover: () => void;
  handleUpdateConsent: () => void;
  selectedCoverId: any;
  isConsentChecked: boolean;
  errorMsg: string;
  isPrepaid: boolean;
  policyPlans: any;
  isPaymentSummaryModalVisible: boolean;
  onHandleClosePaymentSummaryPopUp: () => void;
  selectedPolicy: any;
  selectedPlan: any;
  _msisdn: any;
  selectedCategory: any;
  selectedPartner: any;
  sendPurchaseOtp: () => void;
  handleNomineeInputChange: any;
  handleInputChange: any;
  errors: any;
  onSaveDataPressed: () => void;
  beneficiariesData: any;
  nomineeData: any;
  newUserData: any;
  handleNewUserInputChange: any;
  handleSaveUserData: () => void;
  isOTPSending: boolean;
  handleToggleMobile: () => void;
  isMobileOpen: boolean;
  handleDeviceInfoInputChange: (field: string, value: string) => void;
  mobileData: any;
}

const Home = (props: HomeScreenProps) => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50; // Adjust the scroll threshold as needed
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll);
    // AOS
    Aos.init();
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Download App modal */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        {/* <Modal.Body> */}
        <div className="modal-bg">
          <div className="modal-left-content">
            <div className="popup-logo-img">
              <img src={logo} alt="" width={113} height={77} />
            </div>
            <div className="main-heading">Download Now!</div>
            <div className="main-content">
              Discover the convenience and peace of mind of our efficient policy
              management and rapid claims processing, delivering seamless
              insurance services, no matter the time or place.
              <div className="popup-arrow-image">
                {/* <img
                    src={popUpArrow}
                    alt="popup-arrow"
                    width={100}
                    height={107}
                  /> */}
              </div>
            </div>
            <Link
              to="https://onelink.to/cg7nvr"
              target="_blank"
              className="d-default"
            >
              <span>DOWNLOAD APP NOW </span>
            </Link>
          </div>
          <div className="pop-up-right-image">
            <img src={popUpLogo} alt="" width={450} height={324} />
          </div>
          <div className="close-modal-button">
            <div onClick={handleClose}>
              <img
                src={closebtn}
                alt="cross-popup-btn"
                width={25}
                height={25}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* Insurance Provider Modal */}
      <InsuranceProvider
        partnersList={props.partnersList}
        isPartnersListModalVisible={props.isPartnersListModalVisible}
        onHandleClosePartnersListModal={props.onHandleClosePartnersListModal}
        onPartnerListPressed={props.onPartnerListPressed}
        isLoading={props.isLoading}
      />

      {/* Insurrance Slider Modal */}
      <InsurranceSlider
        partnerPolicies={props.partnerPolicies}
        isPartnerPoliciesModalVisible={props.isPartnerPoliciesModalVisible}
        onHandleClosePartnerPolciesModal={
          props.onHandleClosePartnerPolciesModal
        }
        onPartnerPolicyPressed={props.onPartnerPolicyPressed}
        selectedPartner={props.selectedPartner}
        isLoading={props.isLoading}
      />

      {/* Insurance Detail Modal */}
      <InsuranceDetail
        policyDetails={props.policyDetails}
        isPolicyDetailsModalVisible={props.isPolicyDetailsModalVisible}
        onHandleClosePolicyDetailsModal={props.onHandleClosePolicyDetailsModal}
        onHandleSelectCover={props.onHandleSelectCover}
        handleRadioChange={props.handleRadioChange}
        handleSelectCover={props.handleSelectCover}
        handleUpdateConsent={props.handleUpdateConsent}
        selectedCoverId={props.selectedCoverId}
        isConsentChecked={props.isConsentChecked}
        errorMsg={props.errorMsg}
        isPrepaid={props.isPrepaid}
        policyPlans={props.policyPlans}
        isLoading={props.isLoading}
      />

      {/* User verification Modal */}
      <UserVerificationPopUp
        isUserVerificationModalVisible={props.isUserVerificationModalVisible}
        onHandleCloseUserVerificationModal={
          props.onHandleCloseUserVerificationModal
        }
        msisdn={props.msisdn}
        isOTPSent={props.isOTPSent}
        handleMsisdnChange={props.handleMsisdnChange}
        handleOnPressSendOTPButton={props.handleOnPressSendOTPButton}
        handleOTPSubmit={props.handleOTPSubmit}
        otp={props.otp}
        inputRefs={props.inputRefs}
        handlePINInputChange={props.handlePINInputChange}
        handlePINInput={props.handlePINInput}
        showResendButton={props.showResendButton}
        timer={props.timer}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
      />

      {/* User Profile Modal */}
      <UserProfilePopup
        isUserProfileModalVisible={props.isUserProfileModalVisible}
        onHandleCloseUserProfileModal={props.onHandleCloseUserProfileModal}
        onHandleSetUpUserProfile={props.onHandleSetUpUserProfile}
        newUserData={props.newUserData}
        handleNewUserInputChange={props.handleNewUserInputChange}
        handleSaveUserData={props.handleSaveUserData}
        errorMsg={props.errorMsg}
        isLoading={props.isLoading}
      />

      {/* User Details Modal */}
      <UserDetailsPopup
        isUserDetailsModalVisible={props.isUserDetailsModalVisible}
        onHandleCloseUserDetailsModal={props.onHandleCloseUserDetailsModal}
        isNominee={props.isNominee}
        isBeneficiary={props.isBeneficiary}
        isMobilePolicy={props.isMobilePolicy}
        isPurchased={props.isPurchased}
        activeTab={props.activeTab}
        handleUpdateActiveTab={props.handleUpdateActiveTab}
        handleTogglePersonal={props.handleTogglePersonal}
        isPersonalOpen={props.isPersonalOpen}
        handleToggleNominee={props.handleToggleNominee}
        isNomineeOpen={props.isNomineeOpen}
        noOfBeneficiaries={props.noOfBeneficiaries}
        handleUpdateNoOfBeneficiaries={props.handleUpdateNoOfBeneficiaries}
        expandedStates={props.expandedStates}
        toggleExpandedState={props.toggleExpandedState}
        options={props.options}
        handleUpdateBeneficiariesData={props.handleUpdateBeneficiariesData}
        handleUpdateNomineeData={props.handleUpdateNomineeData}
        relationsData={props.relationsData}
        personalData={props.personalData}
        msisdn={props.msisdn}
        // onHandleSubmitUserDetailsPressed={
        //   props.onHandleSubmitUserDetailsPressed
        // }
        errorMsg={props.errorMsg}
        handleNomineeInputChange={props.handleNomineeInputChange}
        handleInputChange={props.handleInputChange}
        errors={props.errors}
        onSaveDataPressed={props.onSaveDataPressed}
        beneficiariesData={props.beneficiariesData}
        nomineeData={props.nomineeData}
        handleToggleMobile={props.handleToggleMobile}
        isMobileOpen={props.isMobileOpen}
        handleDeviceInfoInputChange={props.handleDeviceInfoInputChange}
        mobileData={props.mobileData}
      />

      {/* User Payment Modal */}
      <UserPaymentPopup
        showPaymentPlanPopup={props.showPaymentPlanPopup}
        onHandleClosePaymentPlanPopup={props.onHandleClosePaymentPlanPopup}
        handleUpdatePaymentMethod={props.handleUpdatePaymentMethod}
        paymentPlan={props.paymentPlan}
        onHandleProceedToPayment={props.onHandleProceedToPayment}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
      />

      <PurchaseOTPPopUp
        onHandleClosePurchaseOTPPopUp={props.onHandleClosePurchaseOTPPopUp}
        showPaymentOtpPopup={props.showPaymentOtpPopup}
        otp={props.otp}
        inputRefs={props.inputRefs}
        handlePINInputChange={props.handlePINInputChange}
        handlePINInput={props.handlePINInput}
        showResendButton={props.showResendButton}
        timer={props.timer}
        onHandleProceedToPayment={props.onHandleProceedToPayment}
        onHandlePressVerifyButton={props.onHandlePressVerifyButton}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
        sendPurchaseOtp={props.sendPurchaseOtp}
        isOTPSending={props.isOTPSending}
      />

      {/* User Payment Summary Modal */}
      <UserPaymentSummary
        isPaymentSummaryModalVisible={props.isPaymentSummaryModalVisible}
        onHandleClosePaymentSummaryPopUp={
          props.onHandleClosePaymentSummaryPopUp
        }
        selectedPolicy={props.selectedPolicy}
        selectedPlan={props.selectedPlan}
        _msisdn={props._msisdn}
        selectedCategory={props.selectedCategory}
        sendPurchaseOtp={props.sendPurchaseOtp}
        isLoading={props.isLoading}
        errorMsg={props.errorMsg}
        // selectedPartner={selectedPartner}
      />

      {/* User Success Modal */}
      <UserSuccesPopup
        showSuccessPopUp={props.showSuccessPopUp}
        handleCloseSuccessPopUp={props.handleCloseSuccessPopUp}
      />

      {/* header */}
      <header className={`header-sec ${scrolled ? "navbar-scrolled" : ""}`}>
        <div className="container">
          <div className="header-wraper">
            <a href="#" className="logo">
              <img src={logo} alt="logo" width={80} height={55} />
            </a>
            <div className={`nav-btn-flex ${isMobileMenuOpen ? "active" : ""}`}>
              <ul className="navList">
                <div className="close-flex dis-mobile">
                  <div className="mob-logo">
                    <img src={logo} alt="logo" width={113} height={77} />
                  </div>
                  <div className="close-btn" onClick={toggleMobileMenu}>
                    <img src={closebtn} alt="close" width={15} height={15} />
                  </div>
                </div>
                <li>
                  <a href="#" onClick={toggleMobileMenu}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="#insurance" onClick={toggleMobileMenu}>
                    Insurance
                  </a>
                </li>
                <li>
                  <a href="#claims" onClick={toggleMobileMenu}>
                    Claims
                  </a>
                </li>
                <li>
                  <a href="#buypolicy" onClick={toggleMobileMenu}>
                    Buy Policy
                  </a>
                </li>
                <li>
                  <a href="#download" onClick={toggleMobileMenu}>
                    Download
                  </a>
                </li>

                {!props.userId ? (
                  <div className="d-btn dis-desk">
                    <button onClick={props.onNavigateToLanding}>Login</button>
                  </div>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      className="headerDropdownbtn"
                      id="dropdown-basic"
                    >
                      <img
                        src={
                          props.personalData &&
                          props.personalData.profile_pic_url &&
                          props.personalData.profile_pic_url.length > 0
                            ? imageUrl + props.personalData.profile_pic_url
                            : userAvatar
                        }
                        // src={userAvatar}
                        alt="user"
                        className="user__avatar"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdownmenu">
                      <Dropdown.Item
                        href="#"
                        onClick={props.onHandleNavigateToProfileScreen}
                      >
                        <img src={profileVector} alt="icon" />
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" onClick={props.onLogout}>
                        <img src={logouticon} alt="icon" />
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {/* {/ profile drop down /} */}

                {/* {/ profile drop down /} */}
              </ul>
              {/* <div className="d-btn dis-desk">
                <button>SUBSCRIBE</button>
              </div> */}
            </div>
            <div className="menubar dis-mobile" onClick={toggleMobileMenu}>
              <img src={menubar} alt="menubar" width={24} height={19} />
            </div>
          </div>
        </div>
      </header>
      <div className="banner-section">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="banner-heading">
                Your Trusted
                <br /> <span>Insurance</span> Partner
              </div>
              <div className="banner-description">
                Discover convenience and peace of mind at your fingertips with
                our efficient policy management and quick claims processing,
                delivering seamless insurance services anytime, anywhere.
              </div>
              <div className="d-btn banner-btn">
                <button
                  onClick={() => {
                    tagManagerEvents(EventsName.DownloadAppButton, "");
                    handleShow();
                  }}
                >
                  Download app now{" "}
                </button>
              </div>
              {/* <div className="satisfaction-section dis-desk">
                <div className="sat-box">
                  <div className="_tnum">12K+</div>
                  <p>Downloads</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">10K+</div>
                  <p>Satisfied Customers</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">2K+</div>
                  <p>Insurance Claims</p>
                </div>
              </div> */}
            </div>
            <div className="col-md-6">
              <div className="banner-img">
                <img src={bannerImg} alt="banner" width={546} height={511} />
              </div>
              {/* <div className="satisfaction-section dis-mobile">
                <div className="sat-box">
                  <div className="_tnum">12K+</div>
                  <p>Downloads</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">10K+</div>
                  <p>Satisfied Customers</p>
                </div>
                <span className="v-line"></span>
                <div className="sat-box">
                  <div className="_tnum">2K+</div>
                  <p>Insurance Claims</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="trusted-customer main-padding"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="trusted-text">
            We have Satisfied & Trusted Customers
          </div>
          <div className="logo-wraper">
            <div className="__customerIcon">
              <img src={bima} alt="bima" width={149} height={71} />
            </div>
            <div className="__customerIcon">
              <img src={waada} alt="waada" width={200} height={66} />
            </div>
            <div className="__customerIcon">
              <img src={efu} alt="efu" width={149} height={71} />
            </div>
            <div className="__customerIcon">
              <img src={webdoc} alt="efu" width={216} height={72} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="offer-insurence"
        id="insurance"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="tc-abs-img">
          <img src={leftside} alt="left side" width={350} height={749} />
        </div>
        <div className="container">
          <div className="main-heading">
            We Offer Different Insurances To Improve <br /> Your Life
          </div>
          <div className="offer-swiper">
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
            >
              {props.insuranceCategories.map(
                (category: any, index: any) =>
                  index % 2 === 0 && (
                    <SwiperSlide>
                      {index < props.insuranceCategories.length && (
                        <div
                          key={category.id}
                          className="swiper-card"
                          onClick={() => {
                            props.onCategoryPressed(
                              props.insuranceCategories[index].id,
                              category
                            );
                            tagManagerEvents(EventsName.productClick, "");
                          }}
                        >
                          <img
                            src={category.icon}
                            alt={category.title}
                            width={136}
                            height={80}
                          />
                          <h3>{props.insuranceCategories[index].title}</h3>
                          <p>{props.insuranceCategories[index].description}</p>
                        </div>
                      )}
                      {index + 1 < props.insuranceCategories.length && (
                        <div
                          key={category.id}
                          className="swiper-card"
                          onClick={() =>
                            props.onCategoryPressed(
                              props.insuranceCategories[index + 1].id,
                              category
                            )
                          }
                        >
                          <img
                            src={props.insuranceCategories[index + 1].icon}
                            alt={props.insuranceCategories[index + 1].title}
                            width={136}
                            height={80}
                          />
                          <h3>{props.insuranceCategories[index + 1].title}</h3>
                          <p>
                            {props.insuranceCategories[index + 1].description}
                          </p>
                        </div>
                      )}
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="claim-section main-padding" id="claims">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src={claim}
                alt="claim"
                className="claimImg"
                width={340}
                height={380}
                data-aos="fade-right"
                data-aos-duration="1500"
              />
            </div>
            <div className="col-md-7">
              <div className="ml" data-aos="fade-left" data-aos-duration="1500">
                <div className="main-heading">CLAIMS</div>
                <div className="claim-desc">
                  Insurance Claims through FikrFree makes it convenient and
                  efficient for policyholders to submit and track claims,
                  ensuring quick resolutions and peace of mind.
                </div>
                <div>
                  <p className="our-exp">
                    <img src={tick} alt="tick" width={26} height={26} />
                    Real-time updates.
                  </p>
                  <p className="our-exp">
                    <img src={tick} alt="tick" width={26} height={26} />
                    User-friendly.
                  </p>
                  <p className="our-exp">
                    <img src={tick} alt="tick" width={26} height={26} />
                    Seamless experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buy-policy main-padding" id="buypolicy">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="main-heading">BUY POLICIES</div>
              <div className="policy-desc">
                Explore a range of polices, from life to handset insurance, and
                find the perfect fit for you at comparative rates. Simplify your
                insurance decisions with FikrFree.
              </div>
              <div className="policy-btn-wraper">
                <div className="d-btn banner-btn">
                  <button onClick={handleShow}>Download now </button>
                </div>
                <div className="learnmore-btn">
                  <button onClick={handleShow}>learn more</button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={buypolicy}
                alt="buy policy"
                className="buypolicymainImg"
                width={546}
                height={600}
                data-aos="fade-left"
                data-aos-duration="1500"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="downloadnow"
        id="download"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="dowmload-wraper">
              <div>
                <img
                  src={downloadImg}
                  alt="download"
                  className="dis-desk"
                  width={1116}
                  height={352}
                />
                <img
                  src={downloadImgmob}
                  alt="download"
                  className="dis-mobile"
                  width={324}
                  height={282}
                />
              </div>
              <div className="download-abs-content">
                <div className="main-heading">
                  Download
                  <img
                    src={logo}
                    alt="logo"
                    className="downloadlogo"
                    width={80}
                    height={55}
                  />
                  now
                </div>
                <div className="download-content">
                  Ensure peace of mind with FikrFree! Secure your insurance
                  needs today for a worry-free future.
                </div>
                <div className="d-btn">
                  <button onClick={handleShow}>Download APP</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section main-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-6">
              <a href="#" className="footlogo">
                <img src={logo} alt="logo" width={80} height={55} />
              </a>
              <div className="foot-desc">
                FikrFree Tailored Protection,
                <br /> Seamless Experience
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="footheading">Our services</div>
              <div className="col-row">
                <div className="foot-col">
                  <div className="footlink">
                    <a href="#insurance">Insurance</a>
                  </div>
                  <div className="footlink">
                    <a href="#claims">Claims</a>
                  </div>
                </div>
                <div className="foot-col">
                  <div className="footlink">
                    <a href="#buypolicy">Buy Policies</a>
                  </div>
                  <div className="footlink">
                    <a href="#download">Download Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12  mob-flex">
              <div className="social-wraper">
                <div className="footheading">Socials</div>
                <div className="foot-social-icon">
                  <a href="https://www.facebook.com/FikerFree" target="_blank">
                    <img src={facebook} alt="facebook" width={50} height={49} />
                  </a>
                  <a
                    href="https://www.instagram.com/fikr.free/"
                    target="_blank"
                  >
                    <img src={insta} alt="insta" width={50} height={48} />
                  </a>
                </div>
              </div>
              <div className="social-wraper pd-left">
                <div className="footheading">Contact Us</div>
                <div className="foot-social-icon">
                  <a
                    href="mailto:info@fikrfree.com.pk"
                    target="_blank"
                    style={{ marginBottom: "10px" }}
                  >
                    info@fikrfree.com.pk
                  </a>
                </div>
                <div
                  className="foot-social-icon foot-desc"
                  style={{ marginBottom: "10px" }}
                >
                  Helpline 111
                </div>
                <div className="foot-social-icon foot-desc">
                  Address: Jazz DHQ-1, Headquarters, 1-A Kohistan Rd, F-8
                  Markaz, Islamabad, 44000
                </div>
              </div>
            </div>
            <div className="copyright">
              © 2024 FikrFree. All rights reserved.
              <br />
              <span>
                <a href="/terms"> Terms & Conditions</a>{" "}
              </span>
              <span className="dot"></span>
              <span>
                <a href="/privacypolicy">Privacy Policy</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`overlay ${isMobileMenuOpen ? "show" : ""}`}
        onClick={toggleMobileMenu}
      ></div>
    </>
  );
};

export default Home;
