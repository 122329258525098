// Staging

// export const baseUrl = "http://fikrfree.ecnstg.com/api/";

// Production

export const baseUrl = "https://fikrfree.com.pk/api/";

export const username = "jazzfikrfreeMAPP";

export const password = "In108ze64F1CwhgpuliqB5n";

export const gtmId = "GTM-TDH75CXP";

export const imageUrl = `https://fikrfree.com.pk/`;
