import axios from "axios";
import { baseUrl } from "../utils/Config";

const newBaseUrl = () => {
  if (window.location.protocol === "http:") {
    let newBaseUrl: string | undefined = baseUrl;
    newBaseUrl = newBaseUrl?.replace("https", "http");
    return newBaseUrl;
  } else {
    return baseUrl;
  }
};

export const getRequest = async (endPoint: string) => {
  const response = await axios({
    url: `${newBaseUrl()}${endPoint}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Connection: "close",
      Authorization: "Bearer " + global.authToken,
    },
  });

  return response;
};
export const postRequest = async (endPoint: string, data: {}) => {
  const response = await axios({
    url: `${newBaseUrl()}${endPoint}`,
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Connection: "close",
      Authorization: "Bearer " + global.authToken,
    },
    data: data,
    // timeout: requestTimeout,
  });

  return response;
};

export const postRequestMulitiPart = async (endPoint: string, data: {}) => {
  const response = await axios({
    url: `${newBaseUrl()}${endPoint}`,
    method: "POST",

    headers: {
      "Content-Type": "multipart/form-data",
      Connection: "close",
      Authorization: "Bearer " + global.authToken,
    },
    data: data,
    // timeout: requestTimeout,
  });

  return response;
};
