import {
  IactivateLPPolicy,
  ICheckUtmResp,
  IgetMetaDataAndFlow,
  IheaderEnrichmentPolicy,
  IPlanResp,
  IsendLPOtp,
} from "../@types/ApiResponses";
import { ApiNames } from "../constants/ApiNames";
// import { fetchLoginPost } from "./FetchAPI";
import {
  postRequest,
  getRequest,
  postRequestMulitiPart,
} from "./NetworkService";

export const checkOperatorService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.checkOperator,
    requestData
  );
  return data;
};

export const generateOTPService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.sendOTP,
    requestData
  );
  return data;
};
export const generateWaadaOTPService = async (requestData: {
  msisdn: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.waadaSendOtp,
    requestData
  );
  return data;
};
export const generateBimaSehatOTPService = async (requestData: {
  msisdn: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.bimaSendOtp,
    requestData
  );
  return data;
};

export const sendLPOtpService = async (requestData: {
  msisdn: string;
  partner_id: number;
}) => {
  let { data }: { data: IsendLPOtp } = await postRequest(
    ApiNames.sendLPOtp,
    requestData
  );
  return data;
};

export const checkUserService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.checkUser,
    requestData
  );
  return data;
};

export const activateMobileInsuranceService = async (requestData: {
  msisdn: string;
  otp: string;
  name: string;
  cnic: number;
  handSetInfo: {
    phone_make: string;
    phone_model: string;
    imei_1: string;
    imei_2: string;
  };
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.waadaActivateMobileInsurance,
    requestData
  );
  return data;
};

export const activateBimaSehatMobileInsuranceService = async (requestData: {
  msisdn: string;
  otp: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.bimaActivateHealthInsurance,
    requestData
  );
  return data;
};
export const bimaActivatePlanService = async (requestData: {
  msisdn: string;
  otp: string;
  plan_id: number;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.bimaActivatePlan,
    requestData
  );
  return data;
};

export const activateLPPolicyService = async (requestData: {
  msisdn: string;
  otp: string;
  plan_id: number;
  utm_source: string;
  source: string;
}) => {
  let { data }: { data: IactivateLPPolicy } = await postRequest(
    ApiNames.subscribe,
    requestData
  );
  return data;
};

export const checkUtmService = async () => {
  let { data }: { data: ICheckUtmResp } = await getRequest(ApiNames.utmList);
  return data;
};
export const subscriptionService = async (requestData: {
  msisdn: string;
  otp: string;
  device_info: string;
  sub_package: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.subscribeUser,
    requestData
  );
  return data;
};

export const LoginService = async (requestData: {
  msisdn: string;
  otp: string;
  device_info: string;
  sub_package: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.loginUser,
    requestData
  );
  return data;
};

export const getProfileService = async (requestData: { user_id: number }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.userProfile,
    requestData
  );
  return data;
};

export const updateProfileService = async (requestData: {
  user_id: any;
  name: string;
  gender: string;
  cnic: number;
  email: string;
  dob: string;
  residential_address: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.updateProfile,
    requestData
  );
  return data;
};

export const uploadProfilePictureService = async (formData: FormData) => {
  let { data }: { data: any } = await postRequestMulitiPart(
    ApiNames.uploadProfilePicture,
    formData
  );
  return data;
};

export const getMetaDataAndFlowService = async (requestData: {
  pp_config_title: string;
  utm_source: string | null;
}) => {
  let { data }: { data: IgetMetaDataAndFlow } = await postRequest(
    ApiNames.getFlow,
    requestData
  );
  return data;
};

export const getHeaderEnrichmentService = async () => {
  let { data }: { data: IheaderEnrichmentPolicy } = await postRequest(
    ApiNames.headerEnrichment,
    {}
  );
  return data;
};

export const getPlanService = async () => {
  const apiName = ApiNames.plans + "/6";
  let { data }: { data: IPlanResp } = await getRequest(apiName);
  return data;
};
