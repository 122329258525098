import React from "react";
import firkFormLogoImg from "../Assets/imgs/landingOne-assets/fikr-logo.webp";
import BackArrow from "../Assets/imgs/back-arrow.webp";
import "../styles/Signup.css";
import defaultloader from "../Assets/imgs/defaultloader.gif";

type Props = {
  msisdn: string;
  onMsisdnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg: string;
  showOtp: boolean;
  onResendClick: () => void;
  counter: number;
  backButton: () => void;
  otp: any;
  inputRefs: any;
  onOTPSubmit: () => void;
  pinInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  // showError: boolean;
  onPINInput: (index: number, e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressSendOTPButton: () => Promise<void>;
  isLoading: boolean;

  mainLoading: boolean;
  uiState: {
    logo: string;
    benefits: string;
    bgImgDesktop: string;
    bgImgMobile: string;
    artWork: string;
  };
  priceState: {
    priceType: string;
    price: string;
  };
  subscribeLoading: boolean;
};

const Signup = (props: Props) => {
  return (
    <>
      {props.mainLoading ? (
        <div className="defaultloader">
          <img src={defaultloader} alt="loader" />
        </div>
      ) : (
        <div>
          <div className="SignUpLandingPage">
            <div className="desktopBg  dis-desk">
              <img
                src={props.uiState.bgImgDesktop}
                alt="background Image"
                width={1920}
                height={1120}
              />
            </div>
            <div className="desktopBg  dis-mobile">
              <img
                src={props.uiState.bgImgMobile}
                alt="background Image"
                width={430}
                height={932}
              />
            </div>
            <div className="landing-content">
              <div className="logo-img">
                <img
                  src={props.uiState.logo}
                  alt="logo"
                  width="120"
                  height="60"
                />
              </div>
              <div className="landing-home-img">
                <img
                  src={props.uiState.artWork}
                  alt="main img"
                  width="375"
                  height="291"
                />
              </div>
              <div className="fikr-landing-form">
                <div className="fikr-form">
                  <div className="fikr-form-logo">
                    <img src={firkFormLogoImg} alt="" width="70" height="58" />
                  </div>
                  {props.showOtp == false && (
                    <>
                      <div className="text">Enter Phone Number</div>
                      <div className="fikr-input">
                        <span>+92</span>
                        <input
                          type="tel"
                          value={props.msisdn}
                          placeholder="3xxxxxxxxx"
                          onChange={props.onMsisdnChange}
                          maxLength={10}
                        />
                      </div>
                    </>
                  )}

                  {/* OTPPPPPPP */}
                  {props.showOtp && (
                    <>
                      <div className="back__arrow">
                        <img
                          src={BackArrow}
                          alt="backarrow"
                          width={25}
                          height={25}
                          onClick={props.backButton}
                        />
                      </div>

                      <div className="otp-form">
                        <div className="text">
                          Please enter OTP received via SMS
                        </div>
                        <div className="fikr-input">
                          {props.otp.map((value: any, index: any) => (
                            <input
                              key={index}
                              ref={(el) =>
                                (props.inputRefs.current[index] = el)
                              }
                              type="tel"
                              name="one"
                              maxLength={1}
                              value={value}
                              onChange={(e) => props.pinInputChange(index, e)}
                              onKeyDown={(e) => props.onPINInput(index, e)}
                            />
                          ))}
                        </div>

                        {/* <p className="error">error</p> */}

                        <div className="charges-text">
                          <div>
                            <div
                              className="__resendOtp"
                              style={{
                                display: props.counter > 0 ? "none" : "block",
                              }}
                            >
                              Didn't receive the OTP?{" "}
                              {props.isLoading ? (
                                <span>Resending OTP</span>
                              ) : (
                                <span
                                  id="otresendSpan"
                                  onClick={props.onResendClick}
                                >
                                  Resend
                                </span>
                              )}
                            </div>

                            <div
                              className="otcounter"
                              id="otcounter"
                              style={{
                                display: props.counter > 0 ? "block" : "none",
                              }}
                            >
                              00:
                              {props.counter < 10
                                ? `0${props.counter}`
                                : props.counter}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* END */}
                  {/* <p className="error">error</p> */}

                  {props.errorMsg.length > 0 ? (
                    <p className="error">{props.errorMsg}</p>
                  ) : null}
                  {props.showOtp ? (
                    <>
                      {props.subscribeLoading ? (
                        <button>Subscribing...</button>
                      ) : (
                        <button onClick={props.onOTPSubmit}>Subscribe</button>
                      )}
                    </>
                  ) : (
                    <>
                      {props.isLoading ? (
                        <button>Sending Otp</button>
                      ) : (
                        <button onClick={props.onPressSendOTPButton}>
                          Send Otp
                        </button>
                      )}
                    </>
                  )}

                  <div className="charges-text">
                    Price Point : {props.priceState.price} PKR{" "}
                    {props.priceState.priceType}*
                  </div>
                  {/* <div className="bimapolicybenifits">
                <ul>
                  <li>
                    Hospitalization benefit PKR 3000/ 30 nights in a year.
                  </li>
                  <li>24/7 unlimited doctor consultations</li>
                </ul>
              </div> */}
                  <div className="term-conditon">
                    <a href="/privacypolicy" target="_blank">
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    <div className="dot"></div>
                    <a href="/terms" target="_blank">
                      {" "}
                      Terms & Conditions{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
